export const BASE_TITLE = 'EBook-Space';
// 本地缓存加密秘钥
export const CryptoJS_KEY = {
    key: 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCeCDcnFrS7DIRbvZLHreVUzaMbAFy2DYmioxBK606urY4rVR8IgLgUhnyw2',
    iv: '0123456789ABCDEF',
};
export const LOGIN_CACHE_TIME = 7 * 24 * 60 * 60 * 1000;
export const TOKEN_CACHE_TIME = 7 * 60 * 60 * 1000;

export const defaultPage = {
    page: 1,
    size: 10,
};

export const urlKey = '/ebook-space-web/';

export const noFilter = [
    '/ebook-space-web/login',
    '/login',
    '/druid/',
    '/',
    '/ebook-space-web/403',
    '/ebook-space-web/error',
    '/ebook-space-web/500',
    '/ebook-space-web/404',
];
