import request from '../utils/request';
import { API_URL } from '../utils/request';

export function deleteInfo(body) {
    return request(API_URL + 'book/deleteUser', 'POST', null, { params: body });
}

export function editInfo(body) {
    return request(API_URL + 'book/updateBook', 'POST', body);
}

export function queryListByNumber(body) {
    return request(API_URL + 'book/queryListByNumber', 'POST', null, { params: body });
}

export function addInfo(body) {
    return request(API_URL + 'book/insertBook', 'POST', body);
}

export function removeRecord(body) {
    return request(API_URL + 'memberBook/deleteRecord', 'POST', null, { params: body });
}
