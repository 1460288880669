import { Divider } from 'antd';
import React from 'react';

const Home = () => {
    // eslint-disable-next-line no-undef
    // const dev = process.env.REACT_APP_NODE_ENV;

    return (
        <div className='appPage'>
            <div>
                <Divider>首页</Divider>
                <div>欢迎登录！</div>
            </div>
        </div>
    );
};

export default Home;
