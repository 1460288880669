/* eslint-disable no-undef */
import React from 'react';
import { Table, Button, Tag } from 'antd';
import { getLable } from '../../../../utils/dict';
import { useNavigate } from 'react-router-dom';
import { urlKey } from '../../../../setting';

const columns = ({ dict }, toBook) => {
    return [
        {
            title: 'ISBN编号',
            dataIndex: 'bookNumber',
            width: 140,
            align: 'center',
            ellipsis: true,
            // render: (text) => <a>{text}</a>,
        },
        {
            title: '书籍名称',
            dataIndex: 'bookName',
            ellipsis: true,
            width: 150,
            render: (bookName, record) => (
                <span>
                    <Button type='link' size={'small'} onClick={() => toBook(record.bookId)}>
                        {bookName}
                    </Button>
                </span>
            ),
        },

        {
            title: '状态',
            dataIndex: 'state',
            align: 'center',
            width: 80,
            render: (state) => (
                <span>
                    <Tag color={state == '1' ? 'success' : 'warning'} key={state}>
                        {getLable(state, dict?.PRE_BOOK_STATE)}
                    </Tag>
                </span>
            ),
        },
        {
            title: '预定数量',
            dataIndex: 'preBookCount',
            ellipsis: true,
            align: 'center',
            width: 120,
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            ellipsis: true,
            align: 'center',
            width: 150,
        },

        {
            title: '更新时间',
            dataIndex: 'updateTime',
            ellipsis: true,
            align: 'center',
            width: 150,
        },
        {
            title: '操作',
            width: 120,
            align: 'center',
            render: (_, record) => (
                <div>
                    <Button name='详情' type='link' size={'small'} onClick={() => toBook(record.bookId)}>
                        详情
                    </Button>
                </div>
            ),
        },
    ];
};

const QueryTable = (props) => {
    const navigate = useNavigate();
    const toMember = (id) => {
        navigate(urlKey + 'book/detail', { state: { id } });
    };

    return (
        <div className='pageTables'>
            <Table
                className='pageTables'
                columns={columns(props, toMember)}
                pagination={false}
                scrollTo={{ x: 1500 }}
                bordered
                rowKey='id'
                size='small'
                // eslint-disable-next-line react/prop-types
                dataSource={props?.data}
            />
        </div>
    );
};
export default QueryTable;
