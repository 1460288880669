import axios from 'axios';
import { message } from 'antd';
import { getToken } from './auth';

// eslint-disable-next-line no-undef
export const API_URL = process.env.REACT_APP_API_URL;

// 创建实例时配置默认请求参数
const instance = axios.create({
    // baseURL: process.env.NODE_ENV === 'development' ? '' : process.env.REACT_APP_API_URL, // 如果有API的基本URL，可以从环境变量中获取
    timeout: 60000, // 设置超时时间
    headers: {
        'Content-Type': 'application/json',
        'Request-Time': new Date().getTime(),
    },
});

instance.interceptors.request.use(
    (config) => {
        // 在这里可以添加一些全局请求拦截器，比如添加认证信息等
        // 在这里可以添加一些请求前处理逻辑，例如添加token
        // const token = localStorage.getItem('token');
        // if (token) {
        //     config.headers['Authorization'] = `Bearer ${token}`;
        // }
        const token = getToken();
        if (token) {
            config.headers['Space-Token'] = token;
        }
        return config;
    },
    (error) => {
        console.error('Error in request interceptor:', error);
        message.error('发起请求异常');
        Promise.reject(error);
    },
);

instance.interceptors.response.use(
    (response) => {
        if (response.status === 200) {
            let data = response.data;
            if (data?.code == '10010') {
                window.location.href = '/ebook-space-web/login';
            }
            return response.data;
        } else {
            message.error('请求响应异常');
            console.log('请求响应异常');
            throw new Error(response.data.message);
        }
    },
    (error) => {
        console.error('Error in response interceptor:', error);
        // 处理响应错误
        // 如果响应状态码为401，可以进行一些操作，例如跳转到登录页面
        if (error.status === 401) {
            window.location.href = '/ebook-space-web/login';
        }
        return Promise.reject(error);
    },
);

export default async function requestObj(url, method, data, options) {
    const { headers, params, ...rest } = options || {};

    try {
        let res;

        switch (method.toLowerCase()) {
            case 'get':
                res = await instance.get(url, { headers, params });
                break;
            case 'post':
                res = await instance.post(url, data, { headers, params, ...rest });
                break;
            case 'put':
                res = await instance.put(url, data, { headers, ...rest });
                break;
            case 'delete':
                res = await instance.delete(url, { headers, params });
                break;
            default:
                throw new Error(`Unsupported HTTP method: ${method}`);
        }
        return res;
    } catch (error) {
        console.log(error);
        throw error;
    }
}
