/**
 * 通用日期加减
 * @author ckk
 * @param dateStr
 * @param type 加减数的类型，d是天、h是小时...
 * @param num 正数加，负数减。ps：支持非整数
 * @returns {Date}
 */
export function addDay(dateStr, type, num) {
    var m = 86400000; // 默认是天
    if (type === 'h') {
        m = m / 24;
    } else if (type === 'm') {
        m = m / 24 / 60;
    } else if (type === 's') {
        m = m / 24 / 60 / 60;
    } else if (type === 'ms') {
        m = m / 24 / 60 / 60 / 1000;
    } else {
        // type == 'd'
        // m = m;
    }
    return new Date(Date.parse(dateStr) + m * num);
}

/**
 * 通用日期格式化
 * @author ckk
 * @param date
 * @param fmt 如："yyyyMMdd"、"yyyy/MM/dd"、"yyyy-MM-dd hh:mm:ss"、"yyyy年MM月dd日"、"yyyy-MM-dd"等等
 * @returns {*}
 */
export function dateFormat(date, fmt) {
    var obj = {
        'M+': date.getMonth() + 1, // 月
        'd+': date.getDate(), // 日
        'h+': date.getHours(), // 时
        'm+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        S: date.getMilliseconds(), // 毫秒
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substring(4 - RegExp.$1.length));
    }
    for (var i in obj) {
        if (new RegExp('(' + i + ')').test(fmt)) {
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length === 1 ? obj[i] : ('00' + obj[i]).substring(('' + obj[i]).length),
            );
        }
    }
    return fmt;
}

/**
 *
 * 格式化日期
 * 获取格式为：1天前 1分钟前  1个月前
 */

export function getDateDiff(dateStr) {
    var publishTime = parseInt(getDate(dateStr).getTime() / 1000);
    var d_seconds;
    var d_minutes;
    var d_hours;
    var d_days;
    var d;
    var timeNow = parseInt(new Date().getTime() / 1000);

    d = timeNow - publishTime;
    d_days = parseInt(d / 86400);
    d_hours = parseInt(d / 3600);
    d_minutes = parseInt(d / 60);
    d_seconds = parseInt(d);

    if (d_days > 0 && d_days < 30) {
        return d_days + '天前';
    } else if (d_days <= 0 && d_hours > 0) {
        return d_hours + '小时前';
    } else if (d_hours <= 0 && d_minutes > 0) {
        return d_minutes + '分钟前';
    } else if (d_seconds < 60) {
        if (d_seconds <= 0) {
            return '刚刚发表';
        } else {
            return d_seconds + '秒前';
        }
    } else if (d_days >= 30 && d_days < 365) {
        return Math.floor(d_days / 30) + '个月前';
    } else if (d_days >= 365) {
        return Math.floor(d_days / 365) + '年前';
    }
}
export function getNowDate(str) {
    var date = new Date();
    return dateFormat(date, str);
}

export function getDate(date) {
    if (!date) {
        return new Date();
    }

    var theDate = '';
    if (typeof date === 'number') {
        theDate = new Date(date);
    } else if (date.length === undefined) {
        theDate = date;
    } else {
        var beforeTime = null;
        var afterTime = null;
        if (date.length > 10) {
            var dateArr = date.split(/[T\s]/);

            beforeTime = dateArr[0].split('-');
            afterTime = dateArr[1].split(':');

            afterTime[2] = afterTime[2] ? afterTime[2] : '00';
        } else {
            beforeTime = date.split('-');
        }

        if (afterTime) {
            theDate = new Date(
                beforeTime[0],
                beforeTime[1] - 1,
                beforeTime[2],
                afterTime[0],
                afterTime[1],
                afterTime[2],
            );
        } else {
            theDate = new Date(beforeTime[0], beforeTime[1] - 1, beforeTime[2]);
        }
    }

    if (!theDate) {
        theDate = new Date();
    }

    return theDate;
}

export function formDataDate(str) {
    var dateStr = getDate(str);
    var year = dateStr.getFullYear();
    var monthStr = dateStr.getMonth() + 1;
    var dayStr = dateStr.getDate();
    var month = monthStr < 10 ? '0' + monthStr : monthStr;
    var day = dayStr < 10 ? '0' + dayStr : dayStr;
    return year + '-' + month + '-' + day;
}

export function formMonthDate(str) {
    var dateStr = getDate(str);
    var year = dateStr.getFullYear();
    var monthStr = dateStr.getMonth() + 1;
    var month = monthStr < 10 ? '0' + monthStr : monthStr;
    return year + '-' + month;
}

export function formDataTime(str) {
    var dateStr = getDate(str);
    var hours = dateStr.getHours();
    var minutes = dateStr.getMinutes();
    var seconds = dateStr.getSeconds();
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    return hours + ':' + minutes + ':' + seconds;
}

/**
 * 获取上一个月
 *
 * @date 格式为yyyy-mm-dd的日期，如：2014-01-25
 */
export function getPreMonth(date) {
    var arr = date.split('-');
    var year = arr[0]; // 获取当前日期的年份
    var month = arr[1]; // 获取当前日期的月份
    var year2 = year;
    var month2 = parseInt(month) - 1;
    if (month2 === 0) {
        year2 = parseInt(year2) - 1;
        month2 = 12;
    }
    if (month2 < 10) {
        month2 = '0' + month2;
    }
    var t2 = year2 + '-' + month2;
    return t2;
}
/**
 * 获取下一个月
 *
 * @date 格式为yyyy-mm的日期，如：2014-01
 */
export function getNextMonth(date) {
    var arr = date.split('-');
    var year = arr[0]; // 获取当前日期的年份
    var month = arr[1]; // 获取当前日期的月份
    var year2 = year;
    var month2 = parseInt(month) + 1;
    if (month2 === 13) {
        year2 = parseInt(year2) + 1;
        month2 = 1;
    }
    if (month2 < 10) {
        month2 = '0' + month2;
    }

    var t2 = year2 + '-' + month2;
    return t2;
}

/**
 * @description: 友好时间显示：
  1分钟以内显示为：刚刚
  1小时以内显示为：N分钟前
  当天以内显示为：今天 N点N分（如：今天 22:33）
  昨天时间显示为：昨天 N点N分（如：昨天 10:15）
  当年以内显示为：N月N日 N点N分（如：02月03日 09:33）
  今年以前显示为：N年N月N日 N点N分（如：2000年09月18日 15:59）
 * @param {*} time
 * @return {*}
 */
const formatTimeFriendly = (time) => {
    const tmDate = new Date(time);
    const timestamp = tmDate.getTime();
    const zeroize = (num) => {
        return (String(num).length === 1 ? '0' : '') + num;
    };
    const curDate = new Date();
    const curTimestamp = curDate.getTime(); // 当前时间戳
    const timestampDiff = curTimestamp - timestamp; // 参数时间戳与当前时间戳相差秒数

    const Y = tmDate.getFullYear();
    const m = tmDate.getMonth() + 1;
    const d = tmDate.getDate();
    const H = tmDate.getHours();
    const i = tmDate.getMinutes();
    // s = tmDate.getSeconds();

    if (timestampDiff < 60) {
        // 一分钟以内
        return '刚刚';
    } else if (timestampDiff < 3600) {
        // 一小时前之内
        return Math.floor(timestampDiff / 60) + '分钟前';
    } else if (curDate.getFullYear() === Y && curDate.getMonth() + 1 === m && curDate.getDate() === d) {
        return `今天${zeroize(H)}:${zeroize(i)}`;
    } else {
        const newDate = new Date((curTimestamp - 86400) * 1000); // 参数中的时间戳加一天转换成的日期对象
        if (newDate.getFullYear() === Y && newDate.getMonth() + 1 === m && newDate.getDate() === d) {
            return `昨天 ${zeroize(H)}:${zeroize(i)}`;
        } else if (curDate.getFullYear() === Y) {
            return `${zeroize(m)}月${zeroize(d)}日 ${zeroize(H)}:${zeroize(i)}`;
        } else {
            return `${Y}年${zeroize(m)}月${zeroize(d)}日 ${zeroize(H)}:${zeroize(i)}`;
        }
    }
};

/**
 * @description: 格式化距现在的已过时间
 * @param { Date } startTime
 * @return { String }
 */
const formatPassTime = (startTime) => {
    const currentTime = Date.parse(new Date());
    const time = currentTime - startTime;
    const day = parseInt(time / (1000 * 60 * 60 * 24));
    const hour = parseInt(time / (1000 * 60 * 60));
    const min = parseInt(time / (1000 * 60));
    const month = parseInt(day / 30);
    const year = parseInt(month / 12);
    if (year) return year + '年前';
    if (month) return month + '个月前';
    if (day) return day + '天前';
    if (hour) return hour + '小时前';
    if (min) return min + '分钟前';
    else return '刚刚';
};

/**
 * @description: 格式化时间 为 mysql timestamp 类型时间
 * @param {*} time
 * @return {*}
 */
const formatTimestamp = (time) => {
    const d = time ? new Date(time) : new Date();
    const yy = d.getFullYear();
    const mm = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
    const dd = d.getDate();
    const hh = d.getHours() < 10 ? '0' + d.getHours() : d.getHours();
    const mf = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
    const ss = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds();
    const timestamp = `${yy}-${mm}-${dd} ${hh}:${mf}:${ss}`;
    return timestamp;
};

/**
 * @description: 格式化现在距结束的剩余时间
 * @param { Date } endTime
 * @return { String }
 */
const formatRemainTime = (endTime) => {
    const startDate = new Date(); // 开始时间
    const endDate = new Date(endTime); // 结束时间
    const t = endDate.getTime() - startDate.getTime(); // 时间差
    let d = 0;
    let h = 0;
    let m = 0;
    let s = 0;
    if (t >= 0) {
        d = Math.floor(t / 1000 / 3600 / 24);
        h = Math.floor((t / 1000 / 60 / 60) % 24);
        m = Math.floor((t / 1000 / 60) % 60);
        s = Math.floor((t / 1000) % 60);
    }
    return d + '天 ' + h + '小时 ' + m + '分钟 ' + s + '秒';
};

/**
 * @description: 是否为闰年
 * @param { Number } year
 * @return { Boolean }
 */
const isLeapYear = (year) => {
    if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
        return true;
    }
    return false;
};

/**
 * @description: 判断是否为同一天
 * @param { Date } date1
 * @param { Date } date2 可选／默认值：当天
 * @return { Boolean }
 */
const isSameDay = (date1, date2) => {
    if (!date2) {
        date2 = new Date();
    }
    const date1_year = date1.getFullYear();
    const date1_month = date1.getMonth() + 1;
    const date1_date = date1.getDate();
    const date2_year = date2.getFullYear();
    const date2_month = date2.getMonth() + 1;
    const date2_date = date2.getDate();

    return date1_date === date2_date && date1_month === date2_month && date1_year === date2_year;
};

/**
 * @description: 获取指定日期月份的总天数
 * @param { Date } time
 * @return { Number }
 */
const getMonthDays = (time) => {
    time = new Date(time);
    const year = time.getFullYear();
    const month = time.getMonth() + 1;
    return new Date(year, month, 0).getDate();
};

export default {
    formatTimeFriendly,
    formatPassTime,
    formatTimestamp,
    formatRemainTime,
    isLeapYear,
    isSameDay,
    getMonthDays,
};
