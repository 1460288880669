/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Descriptions, Spin } from 'antd';
import { getLable } from '../../../../utils/dict';
import useFetch from '../../../../hooks/Request';

const getItem = (key, label, value) => {
    return {
        key,
        label,
        children: value,
    };
};

const MemberDesc = (props) => {
    const [dict, setDict] = useState([]);
    const [isQuery, setIsQuery] = useState(null);
    const [queryValue, setQueryValue] = useState(null);
    const [response, setResponse] = useState({});
    const [record, setRecord] = useState(null);
    const [loading, setLoading] = useState(false);
    useFetch('member/queryUserById', isQuery, 'post', queryValue, null, setResponse, setLoading);

    useEffect(() => {
        if (props?.dict) {
            setDict(props?.dict);
        }
    }, [props?.dict]);

    useEffect(() => {
        if (props?.id) {
            setQueryValue({ memberId: props?.id });
            setIsQuery(1);
        }
    }, [props?.id]);

    useEffect(() => {
        if (response?.code == '200' && response?.data != null) {
            let record = [];
            // 数据格式化
            let data = response?.data;
            let count = 1;
            record.push(getItem(count++, '序列', data['id']));
            record.push(getItem(count++, '会员ID', data['number']));
            record.push(getItem(count++, '昵称', data['nick']));
            record.push(getItem(count++, '手机号', data['mobile']));
            record.push(getItem(count++, '微信标识', data['openId']));
            record.push(getItem(count++, '邮箱', data['mail']));
            record.push(getItem(count++, '称呼', getLable(data['title'], dict?.SEX_INFO)));
            record.push(getItem(count++, '状态', getLable(data['status'], dict?.STATE)));
            record.push(getItem(count++, '会员等级', data['memberLevelName']));
            record.push(getItem(count++, '账户余额', data['accountBalance']));
            record.push(getItem(count++, '会员积分', data['accountPoints']));
            record.push(getItem(count++, '创建时间', data['createTime']));
            record.push(getItem(count++, '最近一次更新', data['updateTime']));
            record.push(getItem(count++, '最近一次修改密码', data['updatePasswordTime']));
            record.push(getItem(count++, '用户描述', data['memo']));
            setRecord(record);
        }
    }, [response]);

    return (
        <>
            <Spin spinning={loading}>
                <div style={{ marginBottom: '10px' }}>{record && <Descriptions items={record} />}</div>
            </Spin>
        </>
    );
};

export default MemberDesc;
