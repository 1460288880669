/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Col, Drawer, Form, Input, Row, Space, Popconfirm, message } from 'antd';
import { useEffect } from 'react';
import { updatePass, getRamdonPass } from '../../../../api/admin';
import { getPass } from '../../../../utils/pass';

const OptionModel = (props) => {
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState(null);
    const [form] = Form.useForm();
    useEffect(() => {
        let { record, open } = props;
        if (record) {
            record.userPassword = null;
            setValues(record);
            setOpen(open);
        }
    }, [props]);

    const onClose = () => {
        form.resetFields();
        setValues(null);
        setOpen(false);
        props?.setOpen(false);
    };

    const saveOption = async (val) => {
        let resp = null;
        let password = getPass(val.userPassword);
        let obj = {
            memberId: values.id,
            password,
        };
        resp = await updatePass(obj);

        if (resp?.code == '200') {
            setOpen(false);
            message.success(resp?.message);
            setValues({});
            props?.setOpen(false);
        } else {
            setOpen(true);
            message.warning(resp?.message);
        }
    };

    const onFinsh = () => {
        form.validateFields()
            .then((val) => {
                saveOption({ ...values, ...val });
            })
            .catch((err) => {
                // 验证不通过时进入
                console.log(err);
            });
    };

    const getRandomPass = async () => {
        let userName = values.userName;
        let resp = await getRamdonPass({ userName });
        if (resp?.code === '200') {
            form.setFieldValue('userPassword', resp?.data);
        } else {
            message.warning(resp?.message);
        }
    };

    return (
        <>
            <Drawer
                title={'重置密码'}
                width={520}
                onClose={onClose}
                open={open}
                destroyOnClose={true}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>取消</Button>
                        <Popconfirm
                            title='确定要保存吗？'
                            placement='topRight'
                            okText='确定'
                            cancelText='取消'
                            onConfirm={onFinsh}>
                            <Button htmlType='submit' type='primary'>
                                保存
                            </Button>
                        </Popconfirm>
                    </Space>
                }>
                <Form initialValues={values} form={form} preserve={false}>
                    <Row gutter={16}>
                        <Col span={20}>
                            <Form.Item
                                name='userNumber'
                                label='账号'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入账号',
                                    },
                                ]}>
                                <Input maxLength={20} allowClear disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name='userPassword'
                                label='密码'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入密码',
                                    },
                                    {
                                        min: 6,
                                        message: '密码长度不能小于6位',
                                    },
                                ]}>
                                <Input.Password maxLength={20} placeholder='请输入密码' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Button htmlType='button' onClick={getRandomPass}>
                                随机密码
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );
};
export default OptionModel;
