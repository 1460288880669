/* eslint-disable react/prop-types */
import { Pagination } from 'antd';
import React from 'react';
import './index.css';
/**
 *
 * @param {*} props
 * @returns
 */

const DataPager = (props) => {
    return (
        <>
            <div className='paginationStyle'>
                <Pagination
                    {...props}
                    size={'small'}
                    showTotal={(total) => `共 ${total} 条`}
                    defaultPageSize={10}
                    defaultCurrent={1}
                    showTitle={false}
                    showSizeChanger
                    pageSizeOptions={[10, 20, 50, 100]}
                />
            </div>
        </>
    );
};

export default DataPager;
