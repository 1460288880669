/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Col, Drawer, Form, Input, Row, Radio, Space, Popconfirm, message, InputNumber } from 'antd';
import { useEffect } from 'react';
import { getOptions } from '../../../../utils/dict';
import { addInfo, editInfo } from '../../../../api/dict';

const OptionModel = (props) => {
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState(null);
    const [title, setTitle] = useState(null);
    const [form] = Form.useForm();
    useEffect(() => {
        let { record, open, title, dictName } = props;
        if (record) {
            if (title == '编辑') {
                setValues(record);
            } else {
                setValues({
                    dictName,
                });
            }
            setTitle(title);
            setOpen(open);
        }
    }, [props]);

    const onClose = () => {
        form.resetFields();
        setValues(null);
        setOpen(false);
        props?.setOpen(false);
    };

    const saveOption = async (val) => {
        let resp = null;
        if (title == '新增') {
            resp = await addInfo(val);
        } else {
            resp = await editInfo(val);
        }
        if (resp?.code == '200') {
            setOpen(false);
            message.success(resp?.message);
            setValues({});
            props?.setOption();
            props?.setOpen(false);
        } else {
            setOpen(true);
            message.warning(resp?.message);
        }
    };

    const onFinsh = () => {
        form.validateFields()
            .then((val) => {
                saveOption({ ...values, ...val });
            })
            .catch((err) => {
                // 验证不通过时进入
                console.log(err);
            });
    };

    return (
        <>
            <Drawer
                title={title}
                width={680}
                onClose={onClose}
                open={open}
                destroyOnClose={true}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>取消</Button>
                        <Popconfirm
                            title='确定要保存吗？'
                            placement='topRight'
                            okText='确定'
                            cancelText='取消'
                            onConfirm={onFinsh}>
                            <Button htmlType='submit' type='primary'>
                                保存
                            </Button>
                        </Popconfirm>
                    </Space>
                }>
                <Form initialValues={values} form={form} preserve={false} layout='vertical'>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name='dictName'
                                label='字典编号'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入字典编号',
                                    },
                                ]}>
                                <Input
                                    maxLength={40}
                                    style={{ width: '220px' }}
                                    placeholder='请输入字典编号'
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name='dictValue'
                                label='数据项-值'
                                maxLength={40}
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入数据项-值',
                                    },
                                ]}>
                                <Input style={{ width: '220px' }} placeholder='请输入数据项-值' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='dictLabel'
                                label='数据项-名称'
                                maxLength={50}
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入数据项-名称',
                                    },
                                ]}>
                                <Input style={{ width: '220px' }} placeholder='请输入数据项-名称' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name='dictState'
                                label='状态'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择状态',
                                    },
                                ]}>
                                <Radio.Group options={props?.dict && getOptions(props?.dict.STATE)}></Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='sort' label='排序'>
                                <InputNumber max={999} style={{ width: '220px' }} min={1} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name='memo' label='描述'>
                                <Input.TextArea
                                    maxLength={200}
                                    style={{ width: '220px' }}
                                    rows={3}
                                    placeholder='请输入描述'
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );
};
export default OptionModel;
