import request from '../utils/request';
import { API_URL } from '../utils/request';

export function getUserInfo(code) {
    return request(API_URL + 'user/getUserInfo', 'POST', { params: code });
}

export function login(body) {
    return request(API_URL + 'auth/adminLogin', 'POST', null, { params: body });
}

export function toLoginOut(body) {
    return request(API_URL + 'auth/userLoginOut', 'POST', null, { params: body });
}
