import { message } from 'antd';

export function exportExcel(response) {
    var dispositionStr = response.headers['content-disposition'];
    if (dispositionStr == null || dispositionStr === '') {
        message.error('下载失败!');
        return;
    }
    // 获取文件名
    let dispositionArr = dispositionStr.split(';');
    // 我们的文件名可能含有汉字,因此在后端进行了UTF-8编码处理,此处进行解码
    let fileName = decodeURIComponent(dispositionArr[1]);
    fileName = fileName.split("filename*=utf-8''")[1];
    const blob = new Blob([response?.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
    });
    console.log(blob);
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName || new Date().getTime + '.xlsx');
    link.click();
    document.body.appendChild(link);
}
