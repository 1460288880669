import { isBlank } from './string';
import { isArray, isBlankArray } from './array';
import { isObject, getObjToMap } from './object';
export function getValue(label, dicts) {
    let value = '';
    if (isBlank(label) || isBlankArray(dicts)) {
        return '';
    }
    dicts.forEach((element) => {
        if (element.dictLabel == label) {
            value = element.dictValue;
            return false;
        }
    });
    return value;
}

export function getLable(value, dicts) {
    let label = '';
    if (isBlank(value) || isBlankArray(dicts)) {
        return '';
    }
    dicts.forEach((element) => {
        if (element.dictValue == value) {
            label = element.dictLabel;
            return false;
        }
    });
    return label;
}

export function getLableList(value, dicts) {
    let label = '';
    if (isBlank(value) || isBlankArray(dicts)) {
        return '';
    }
    let arr = value.split(',');
    for (var i = 0; i < arr.length; i++) {
        let id = arr[i];
        for (let j in dicts) {
            let element = dicts[j];
            if (element.dictValue == id) {
                console.log(element);
                label += element.dictLabel + ', ';
            }
        }
    }
    return label;
}

export function getLevelLable(value, dicts) {
    let label = '';
    if (isBlank(value) || isBlankArray(dicts)) {
        return '';
    }
    dicts.forEach((element) => {
        if (element.id == value) {
            label = element.levelName;
            return false;
        }
    });
    return label;
}
export function getLevelOptions(dicts) {
    let options = [];
    if (isBlankArray(dicts)) {
        return [];
    }
    dicts.forEach((item) => {
        options.push({
            label: item?.levelName,
            value: item?.id,
        });
    });
    return options;
}
export function getOptions(dicts) {
    let options = [];
    if (isBlankArray(dicts)) {
        return [];
    }
    dicts.forEach((item) => {
        options.push({
            label: item?.dictLabel,
            value: item?.dictValue,
        });
    });
    return options;
}

function getFormItem(name, value) {
    return {
        name,
        value,
    };
}

export function getFormFields(obj, noArr = []) {
    if (isObject(obj)) {
        let arr = [];
        let objMap = getObjToMap(obj);
        objMap.forEach(([key, value]) => {
            if (!noArr.includes(key)) {
                arr.push(getFormItem(key, value));
            }
        });
        return arr;
    }
    return [];
}

function getTreeItem(key, name, children) {
    return {
        title: key + '-' + name,
        key,
        children,
    };
}

export function getTreeData(data) {
    if (isArray(data)) {
        let arr = [];
        data.forEach((item) => {
            arr.push(
                getTreeItem(
                    item.number,
                    item.name,
                    item.children && item.children.length ? getTreeData(item.children) : [],
                ),
            );
        });
        return arr;
    }
    return [];
}

export function getAdminTreeData(data) {
    if (isArray(data)) {
        let arr = [];
        data.forEach((item) => {
            arr.push(
                getTreeItem(
                    item.menuCode,
                    item.menuName,
                    item.children && item.children.length ? getAdminTreeData(item.children) : [],
                ),
            );
        });
        return arr;
    }
    return [];
}
