import request from '../utils/request';
import { API_URL } from '../utils/request';

export function editInfo(body) {
    return request(API_URL + 'dict/updateInfo', 'POST', body);
}

export function addInfo(body) {
    return request(API_URL + 'dict/insertInfo', 'POST', body);
}

export function updateCache() {
    return request(API_URL + 'dict/updateCache', 'POST');
}
