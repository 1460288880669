import { useEffect } from 'react';
import request from '../../utils/request';
import { message } from 'antd';
import { API_URL } from '../../utils/request';
/**
 * 用来进行数据列表渲染
 * @param {*} url  请求url
 * @param {*} shouldFetch  是否请求
 * @param {*} method  请求方式  get、post
 * @param {*} param  请求参数
 * @param {*} body  请求体  param 和body 后端接收方式不一样
 * @returns
 */

function useFetch(url, shouldFetch, method = 'post', param, body, setResponse, setLoading = null) {
    const fetchData = async () => {
        try {
            if (setLoading) setLoading(true);
            const response = await request(API_URL + url, method, body, { params: param });
            setResponse(response);
            if (response?.code != '200') {
                message.warning(response?.message);
            }
        } catch (error) {
            console.log(error.message);
            message.error(error.message);
        }
        if (setLoading) setLoading(false);
    };
    useEffect(() => {
        if (shouldFetch) fetchData();
    }, [shouldFetch]);
}

export default useFetch;
