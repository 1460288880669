/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    Row,
    Select,
    Space,
    Popconfirm,
    message,
    InputNumber,
    DatePicker,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { getOptions } from '../../../../utils/dict';
import { getNowDate, dateFormat } from '../../../../utils/date';
import { addInfo, editInfo } from '../../../../api/voucher';

const dateFormat1 = 'YYYY-MM-DD';
const nowDate = getNowDate('yyyy-MM-dd');

const OptionModel = (props) => {
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState(null);
    const [title, setTitle] = useState(null);
    const [form] = Form.useForm();
    useEffect(() => {
        let { record, open, title } = props;
        if (record) {
            setValues(record);
            setTitle(title);
            setOpen(open);
        }
    }, [props]);

    const onClose = () => {
        form.resetFields();
        setValues(null);
        setOpen(false);
        props?.setOpen(false);
    };

    const saveOption = async (val) => {
        let resp = null;
        if (title == '新增') {
            resp = await addInfo(val);
        } else {
            resp = await editInfo(val);
        }
        if (resp?.code == '200') {
            setOpen(false);
            message.success(resp?.message);
            setValues({});
            props?.setOption();
            props?.setOpen(false);
        } else {
            setOpen(true);
            message.warning(resp?.message);
        }
    };

    const onFinsh = () => {
        form.validateFields()
            .then((val) => {
                console.log(val);
                val.voucherDate = dateFormat(val.voucherDate1.$d, 'yyyy-MM-dd');
                saveOption({ ...values, ...val });
            })
            .catch((err) => {
                // 验证不通过时进入
                console.log(err);
            });
    };

    return (
        <>
            <Drawer
                title={title}
                width={680}
                onClose={onClose}
                open={open}
                destroyOnClose={true}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>取消</Button>
                        <Popconfirm
                            title='确定要保存吗？'
                            placement='topRight'
                            okText='确定'
                            cancelText='取消'
                            onConfirm={onFinsh}>
                            <Button htmlType='submit' type='primary'>
                                保存
                            </Button>
                        </Popconfirm>
                    </Space>
                }>
                <Form initialValues={values} form={form} preserve={false} layout='vertical'>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name='voucherNum' label='充值卡编号'>
                                <Input
                                    style={{ width: '220px' }}
                                    placeholder='请输入充值卡编号(可不输入)'
                                    disabled={title == '编辑'}
                                />
                            </Form.Item>
                        </Col>
                        {title == '编辑' && (
                            <Col span={12}>
                                <Form.Item name='cvvCode' label='CVV校验码'>
                                    <Input
                                        style={{ width: '220px' }}
                                        placeholder='请输入CVV校验码'
                                        disabled={title == '编辑'}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name='voucherName'
                                label='充值卡名称'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入充值卡名称',
                                    },
                                ]}>
                                <Input style={{ width: '220px' }} placeholder='请输入充值卡名称' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='voucherType'
                                label='类型'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择类型',
                                    },
                                ]}>
                                <Select
                                    style={{ width: '220px' }}
                                    placeholder='请选择类型'
                                    allowClear={true}
                                    options={getOptions(props?.dict?.VOUCHER_TYPE || [])}></Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name='voucherAmount'
                                label='充值卡面额'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入充值卡面额',
                                    },
                                ]}>
                                <InputNumber style={{ width: 220 }} min={0} step='1' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='voucherUnit'
                                label='单位'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择单位',
                                    },
                                ]}>
                                <Select
                                    style={{ width: '220px' }}
                                    placeholder='请选择单位'
                                    allowClear={true}
                                    options={getOptions(props?.dict?.VOUCHER_UNIT || [])}></Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name='voucherDate1'
                                label='发行日期'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择发行日期',
                                    },
                                ]}>
                                <DatePicker
                                    format={dateFormat1}
                                    style={{ width: '220px' }}
                                    minDate={dayjs(nowDate, dateFormat1)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='expireLength'
                                label='有效期（月）'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入有效期',
                                    },
                                ]}>
                                <InputNumber style={{ width: 220 }} min={0} step='1' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        {title == '新增' && (
                            <Col span={12}>
                                <Form.Item
                                    name='count'
                                    label='创建数量'
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入创建数量(单次最大999)',
                                        },
                                    ]}>
                                    <InputNumber
                                        placeholder='编号存在时为1'
                                        style={{ width: 220 }}
                                        max={999}
                                        min={1}
                                        step='1'
                                    />
                                </Form.Item>
                            </Col>
                        )}
                        <Col span={12}>
                            <Form.Item
                                name='state'
                                label='状态'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择状态',
                                    },
                                ]}>
                                <Select
                                    style={{ width: '220px' }}
                                    placeholder='请选择状态'
                                    allowClear={true}
                                    options={getOptions(props?.dict?.VOUCHER_CARD_STATE || [])}></Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='memo' label='描述'>
                                <Input.TextArea rows={3} placeholder='请输入充值卡描述' />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );
};
export default OptionModel;
