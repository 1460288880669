import React, { lazy } from 'react';
import { Row, Tabs } from 'antd';
import useDict from '../../../hooks/Dict';
import useDocumentTitle from '../../../hooks/DocumentTitle';
import BreadcrumbTitle from '../../../components/BreadcrumbTitle';

import BookInfo from './component/BookInfo';
const BookInfoPre = lazy(() => import('./component/BookInfoPre'));
const BookInfoSuc = lazy(() => import('./component/BookInfoSuc'));
const BookInfoFail = lazy(() => import('./component/BookInfoFail'));
import './index.css';

const items = (dict) => {
    return [
        {
            key: '1',
            label: '全部',
            children: <BookInfo dict={dict} />,
        },
        {
            key: '2',
            label: '在库',
            children: <BookInfoSuc dict={dict} />,
        },
        {
            key: '3',
            label: '待采',
            children: <BookInfoPre dict={dict} />,
        },
        {
            key: '4',
            label: '禁出',
            children: <BookInfoFail dict={dict} />,
        },
    ];
};

const BookList = () => {
    const dict = useDict('BOOK_STATE,MONEY_UNIT,YSEORNO,BOOK_INTEREST,RESTICTION_TYPE,BOOK_FILE_TYPE,BOOK_LANGUAGE');
    useDocumentTitle('书籍列表');

    return (
        <div className='appPage'>
            <div className='pageContent'>
                <BreadcrumbTitle list={['书籍管理', '书籍列表']} />
                <Row>
                    <Tabs style={{ width: '100%' }} defaultActiveKey='1' items={items(dict)}></Tabs>
                </Row>
            </div>
        </div>
    );
};

export default BookList;
