import Cookies from 'js-cookie';
import { isBlank } from './string';

const tokenKey = 'Space-Token';
export const userKey = 'SPACE_USER_INFO';

export function getToken() {
    return Cookies.get(tokenKey);
}

export function setToken(token) {
    return Cookies.set(tokenKey, token);
}

export function removeToken() {
    return Cookies.remove(tokenKey);
}

// 存储用户信息
export function storeCache(key, obj, ttl) {
    const now = new Date();
    // 过期时间对象
    const item = {
        value: obj,
        expires: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
}

// 获取用户信息
export function getCache(key) {
    if (isBlank(key)) {
        return null;
    }
    try {
        const item = JSON.parse(localStorage.getItem(key));
        if (item) {
            // 判断过期时长
            const now = new Date();
            if (now.getTime() > item.expires) {
                // 数据已过期，删除并返回null
                localStorage.removeItem(key);
                return null;
            }
            return item.value;
        }
    } catch (e) {
        // 处理错误
        console.log(e);
    }
    return null;
}

export function removeCache(key) {
    localStorage.removeItem(key);
}
