/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Descriptions, Spin } from 'antd';
import { getLable, getLableList } from '../../../../utils/dict';
import useFetch from '../../../../hooks/Request';

const getItem = (key, label, value) => {
    return {
        key,
        label,
        children: value,
    };
};

const BookDesc = (props) => {
    const [dict, setDict] = useState([]);
    const [isQuery, setIsQuery] = useState(null);
    const [queryValue, setQueryValue] = useState(null);
    const [response, setResponse] = useState({});
    const [record, setRecord] = useState(null);
    const [loading, setLoading] = useState(false);
    useFetch('book/queryBookById', isQuery, 'post', queryValue, null, setResponse, setLoading);

    useEffect(() => {
        if (props?.dict) {
            setDict(props?.dict);
        }
    }, [props?.dict]);

    useEffect(() => {
        if (props?.id) {
            setQueryValue({ bookId: props?.id });
            setIsQuery(1);
        }
    }, [props?.id]);

    useEffect(() => {
        if (response?.code == '200' && response?.data != null) {
            let record = [];
            // 数据格式化
            let data = response?.data;
            let count = 1;
            record.push(getItem(count++, '序列', data['id']));
            record.push(getItem(count++, 'ISBN编号', data['isbnNumber']));
            record.push(getItem(count++, '书籍名称', data['bookName']));
            record.push(getItem(count++, '作者', data['bookWriter']));
            record.push(getItem(count++, '语种', getLable(data['bookLanguage'], dict?.BOOK_LANGUAGE)));
            record.push(getItem(count++, '发行年份', data['pubYear']));
            record.push(getItem(count++, '版本', data['bookVersion']));
            record.push(getItem(count++, '状态', getLable(data['state'], dict?.BOOK_STATE)));
            record.push(getItem(count++, '文件类型', getLable(data['fileType'], dict?.BOOK_FILE_TYPE)));
            record.push(getItem(count++, '文件大小(MB)', data['fileSize']));
            record.push(getItem(count++, '发行价(元)', data['pubPrice']));
            record.push(getItem(count++, 'E书价(e币)', data['bookPrice']));
            record.push(getItem(count++, '分类', getLableList(data['categories'], dict?.BOOK_INTEREST)));
            record.push(getItem(count++, '限制类型', getLableList(data['restictionType'], dict?.RESTICTION_TYPE)));
            record.push(getItem(count++, '创建时间', data['createTime']));
            record.push(getItem(count++, '最近一次更新', data['updateTime']));
            record.push(getItem(count++, '描述', data['memo']));
            setRecord(record);
        }
    }, [response]);

    return (
        <>
            <Spin spinning={loading}>
                <div style={{ marginBottom: '10px' }}>{record && <Descriptions items={record} />}</div>
            </Spin>
        </>
    );
};

export default BookDesc;
