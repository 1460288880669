import React, { lazy } from 'react';
import { Row, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import useDict from '../../../hooks/Dict';
import useFetch from '../../../hooks/Request';
import { useLocation } from 'react-router-dom';
import useDocumentTitle from '../../../hooks/DocumentTitle';
import BreadcrumbTitle from '../../../components/BreadcrumbTitle';

import MemberDesc from './component/MemberDesc';
import MemberBook from './component/MemberBook';

const EditPass = lazy(() => import('./component/EditPass'));
const MemberInterest = lazy(() => import('./component/MemberInterest'));
const VoucherRecord = lazy(() => import('./component/VoucherRecord'));
const PointsRecord = lazy(() => import('./component/PointsRecord'));
const InvitationRecord = lazy(() => import('./component/InvitationRecord'));

const items = (id, dict, memberLevelList) => {
    return [
        {
            key: '1',
            label: '订阅记录',
            children: <MemberBook id={id} dict={dict} />,
        },

        {
            key: '2',
            label: '充值记录',
            children: <VoucherRecord id={id} dict={dict} />,
        },
        {
            key: '6',
            label: '下线会员',
            children: <InvitationRecord id={id} levelList={memberLevelList} dict={dict} />,
        },

        {
            key: '5',
            label: '积分记录',
            children: <PointsRecord id={id} dict={dict} />,
        },

        {
            key: '3',
            label: '兴趣',
            children: <MemberInterest id={id} dict={dict} />,
        },

        {
            key: '4',
            label: '修改密码',
            children: <EditPass id={id} dict={dict} />,
        },
    ];
};

const MemberDetail = () => {
    const [id, setId] = useState(null);
    const location = useLocation();
    const [memberLevelList, setMemberLevelList] = useState({});

    const dict = useDict(
        'STATE,SEX_INFO,BOOK_STATE,VOUCHER_STATE,VOUCHER_TYPE,MONEY_UNIT,VOUCHER_UNIT,ORDER_TYPE,POINT_TYPE,MEMBER_BOOK_STATE',
    );
    useDocumentTitle('会员详情');

    useFetch('memberLevel/queryList', 1, 'post', null, null, setMemberLevelList);

    useEffect(() => {
        if (location.state?.id) {
            setId(location.state.id);
        }
    }, []);

    return (
        <div className='appPage'>
            <div className='pageContent'>
                <BreadcrumbTitle list={['会员管理', '会员列表', '会员详情']} />
                {id && <MemberDesc dict={dict} levelList={memberLevelList?.data} id={id} />}
                <Row>
                    <Tabs
                        style={{ width: '100%' }}
                        defaultActiveKey='1'
                        items={items(id, dict, memberLevelList?.data)}></Tabs>
                </Row>
            </div>
        </div>
    );
};

export default MemberDetail;
