/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React from 'react';
import { Table, Tag, Button, Spin, Row, Form, Input, Select, message } from 'antd';
import { getLable, getOptions } from '../../../../utils/dict';
import { useState, useEffect } from 'react';
import DataPager from '../../../../components/DataPager';
import { useNavigate } from 'react-router-dom';
import useFetch from '../../../../hooks/Request';
import { urlKey } from '../../../../setting';

import { removeRecord } from '../../../../api/book';

const columns = (dict, remove, toMember) => {
    return [
        {
            title: '订单编号',
            dataIndex: 'orderNumber',
            align: 'center',
            width: 100,
            ellipsis: true,
        },
        {
            title: '会员名称',
            dataIndex: 'memberName',
            align: 'center',
            width: 100,
            ellipsis: true,
            render: (memberName, record) => (
                <span>
                    <Button type='link' size={'small'} onClick={() => toMember(record.memberId)}>
                        {memberName}
                    </Button>
                </span>
            ),
        },
        {
            title: '会员编号',
            dataIndex: 'memberNumber',
            align: 'center',
            width: 100,
            ellipsis: true,
        },
        {
            title: '会员手机号',
            dataIndex: 'memberMobile',
            align: 'center',
            width: 100,
            ellipsis: true,
        },
        {
            title: '订阅状态',
            dataIndex: 'state',
            align: 'center',
            width: 80,
            render: (state) => (
                <span>
                    <Tag color={state == '1' ? 'success' : 'warning'} key={state}>
                        {getLable(state, dict.MEMBER_BOOK_STATE)}
                    </Tag>
                </span>
            ),
        },
        {
            title: '订单类型',
            dataIndex: 'orderType',
            align: 'center',
            width: 80,
            render: (orderType) => (
                <span>
                    <Tag color={state == '1' ? 'processing' : 'warning'} key={orderType}>
                        {getLable(orderType, dict.ORDER_TYPE)}
                    </Tag>
                </span>
            ),
        },
        {
            title: '订阅价(e币)',
            dataIndex: 'payPrice',
            align: 'center',
            width: 80,
        },
        {
            title: '订单折扣',
            dataIndex: 'discountRate',
            align: 'center',
            width: 80,
            ellipsis: true,
        },
        {
            title: '订阅时间',
            dataIndex: 'createTime',
            align: 'center',
            ellipsis: true,
            width: 150,
        },
    ];
};

const BookMember = (props) => {
    const defaultPage = {
        page: 1,
        size: 10,
    };
    const [bookId, setBookId] = useState(null);
    const [pageValue, setPageValue] = useState(defaultPage);
    const [formValue, setFormValue] = useState({});
    const [isQuery, setIsQuery] = useState(null);
    const [queryValue, setQueryValue] = useState(pageValue);
    const [response, setResponse] = useState({});
    const [loading, setLoading] = useState(false);
    useFetch('memberBook/queryListByBookPage', isQuery, 'post', queryValue, null, setResponse, setLoading);

    useEffect(() => {
        if (props?.id) {
            let id = props?.id;
            setBookId(id);
            setFormValue({ bookId: id });
            toQuery({ bookId: id }, defaultPage);
        }
    }, [props?.id]);

    const navigate = useNavigate();

    const onFinish = (values) => {
        setFormValue({ ...values, bookId });
        setPageValue(defaultPage);
        toQuery({ ...values, bookId }, defaultPage);
    };

    const toQuery = (val, page) => {
        // 查询页面
        let data = {
            ...val,
            ...page,
        };
        setQueryValue(data);
        setIsQuery(isQuery + 1);
    };

    const pageChange = (page, size) => {
        setPageValue({ page, size });
        toQuery(formValue, { page, size });
    };

    const remove = async (id, state) => {
        const resp = await removeRecord({ id, state });
        if (resp?.code == '200') {
            message.success(resp?.message);
            toQuery(formValue, defaultPage);
        } else {
            message.warning(resp?.message);
        }
    };

    const toMember = (id) => {
        navigate(urlKey + 'member/detail', { state: { id } });
    };

    return (
        <>
            <Spin spinning={loading}>
                <Row>
                    <div style={{ marginBottom: '10px' }}>
                        <Form layout='inline' size='small' onFinish={onFinish}>
                            <Form.Item name='memberNum' label='会员编号'>
                                <Input style={{ width: '160px' }} allowClear placeholder='请输入会员编号' />
                            </Form.Item>
                            <Form.Item name='memberMobile' label='会员手机号'>
                                <Input style={{ width: '160px' }} allowClear placeholder='请输入会员手机号' />
                            </Form.Item>
                            <Form.Item name='orderType' label='订单类型'>
                                <Select
                                    style={{ width: '160px' }}
                                    placeholder='请选择订单类型'
                                    allowClear={true}
                                    options={getOptions(props?.dict?.ORDER_TYPE || [])}></Select>
                            </Form.Item>
                            <Form.Item name='state' label='订阅状态'>
                                <Select
                                    style={{ width: '160px' }}
                                    placeholder='请选择订阅状态'
                                    allowClear={true}
                                    options={getOptions(props?.dict?.MEMBER_BOOK_STATE || [])}></Select>
                            </Form.Item>
                            <Form.Item>
                                <Button type='primary' htmlType='submit'>
                                    查询
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Row>
                <Row>
                    <Table
                        columns={columns(props?.dict, remove, toMember)}
                        pagination={false}
                        bordered
                        size='small'
                        dataSource={response?.data}
                    />
                </Row>
                <Row>
                    <DataPager
                        total={response?.total}
                        current={pageValue?.page}
                        pageSize={pageValue?.size}
                        onChange={pageChange}
                    />
                </Row>
            </Spin>
        </>
    );
};
export default BookMember;
