import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const ERROR = () => {
    const navigate = useNavigate();
    return (
        <Result
            status='500'
            title='500'
            style={{
                background: 'none',
            }}
            subTitle='对不起，服务器响应异常。'
            extra={
                <Button type='primary' onClick={() => navigate('/')}>
                    Back Home
                </Button>
            }
        />
    );
};

export default ERROR;
