import { message, Checkbox, Button, Form, Input, Divider, Spin } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../../api/auth';
import { loginSuccess } from '../../store/actions/loginSuccess';
import { setToken, storeCache, getCache, userKey } from '../../utils/auth';
import { getPass, aes } from '../../utils/pass';
import useTimeout from '../../hooks/TimeOut';
import useDocumentTitle from '../../hooks/DocumentTitle';
import { LOGIN_CACHE_TIME, TOKEN_CACHE_TIME, urlKey } from '../../setting';

import './index.css';

const USRE_KEY = 'el_user_login_cache';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [startFlag, setStartFlag] = useState(0);
    const [loginValue, setLoginValue] = useState(null);
    useDocumentTitle('登录');

    useTimeout(
        () => {
            setLoading(false);
            navigate(urlKey + 'dashboard');
        },
        500,
        startFlag,
    );

    useEffect(() => {
        //获取缓存
        const value = getCache(USRE_KEY);
        if (value) {
            value.password = aes.de(value.password);
            setLoginValue(value);
        } else {
            setLoginValue({});
        }
    }, []);

    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            let password = getPass(values.password);
            const data = await login({ ...values, password });
            if (data.code == '200') {
                const user = data.data.user;
                const token = data.data.token;
                setToken(token);
                // 设置登录状态
                dispatch(loginSuccess(user));
                storeCache(userKey, user, TOKEN_CACHE_TIME);
                if (values.remember) {
                    // 加密
                    let password = aes.en(values.password);
                    storeCache(USRE_KEY, { ...values, password }, LOGIN_CACHE_TIME);
                }
                setStartFlag(1);
            } else {
                messageApi.open({
                    type: 'warning',
                    content: data.message,
                });
            }
        } catch (error) {
            console.log(error);
            messageApi.open({
                type: 'error',
                content: '登录失败，请重试！',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {contextHolder}
            <Spin spinning={loading}>
                <div className='container'>
                    <div className='loginTitle'>
                        {' '}
                        <Divider style={{ fontSize: '30px' }}>EBook-Space后台管理系统</Divider>
                    </div>
                    <div className='content'>
                        <Divider>账号密码登录</Divider>
                        {loginValue && (
                            <Form
                                name='basic'
                                wrapperCol={{
                                    span: 24,
                                }}
                                style={{
                                    maxWidth: 600,
                                }}
                                initialValues={{ ...loginValue }}
                                className='login_form'
                                autoComplete='off'
                                onFinish={(values) => {
                                    handleSubmit(values);
                                }}>
                                <Form.Item
                                    label=''
                                    name='userNumber'
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入用户名!',
                                        },
                                    ]}>
                                    <Input
                                        size='large'
                                        prefix={<UserOutlined className='site-form-item-icon' />}
                                        placeholder='用户名'
                                    />
                                </Form.Item>
                                <Form.Item
                                    label=''
                                    name='password'
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入密码!',
                                        },
                                    ]}>
                                    <Input.Password
                                        size='large'
                                        prefix={<LockOutlined className='site-form-item-icon' />}
                                        placeholder='密码'
                                    />
                                </Form.Item>

                                <Form.Item>
                                    <Form.Item
                                        name='remember'
                                        valuePropName='checked'
                                        noStyle
                                        style={{ float: 'left' }}>
                                        <Checkbox>记住密码</Checkbox>
                                    </Form.Item>

                                    {/* <a className='login_form_forgot' href=''>
                                        <Button type='link'>忘记密码</Button>
                                    </a> */}
                                </Form.Item>
                                <Form.Item>
                                    <Button type='primary' htmlType='submit' className='login_form_button'>
                                        登录
                                    </Button>
                                </Form.Item>
                            </Form>
                        )}
                    </div>
                </div>
            </Spin>
        </>
    );
};

export default Login;
