/* eslint-disable no-undef */
import React from 'react';
import { Table, Tag, Button } from 'antd';
import { getLable } from '../../../../utils/dict';

const columns = ({ remove, edit, dict }) => {
    return [
        {
            title: '等级编号',
            dataIndex: 'levelNumber',
            ellipsis: true,
            align: 'center',
            width: 120,
        },
        {
            title: '等级名称',
            dataIndex: 'levelName',
            ellipsis: true,
            width: 120,
        },
        {
            title: '积分区间',
            dataIndex: 'minPoints',
            ellipsis: true,
            align: 'center',
            width: 150,
            render: (_, record) => (
                <span>
                    {record?.minPoints} - {record?.maxPoints}
                </span>
            ),
        },
        {
            title: '会员折扣',
            dataIndex: 'discountRate',
            align: 'center',
            ellipsis: true,
            width: 80,
        },
        {
            title: '状态',
            dataIndex: 'state',
            align: 'center',
            width: 80,
            render: (state) => (
                <span>
                    <Tag color={state == 1 ? 'success' : 'warning'} key={state}>
                        {getLable(state, dict.STATE)}
                    </Tag>
                </span>
            ),
        },
        {
            title: '是否默认',
            dataIndex: 'levelDefault',
            align: 'center',
            width: 80,
            render: (levelDefault) => (
                <span>
                    <Tag color={levelDefault == 1 ? 'success' : 'processing'} key={levelDefault}>
                        {getLable(levelDefault, dict.YSEORNO)}
                    </Tag>
                </span>
            ),
        },
        {
            title: '更新时间',
            dataIndex: 'updateTime',
            ellipsis: true,
            width: 150,
        },

        {
            title: '操作',
            width: 120,
            align: 'center',
            render: (_, record) => (
                <div>
                    <Button type='link' size={'small'} onClick={() => edit(record)}>
                        编辑
                    </Button>
                    {record.state == '1' && (
                        <Button type='link' size={'small'} onClick={() => remove(record, '-1')}>
                            禁用
                        </Button>
                    )}
                    {record.state == '-1' && (
                        <Button type='link' size={'small'} onClick={() => remove(record, '1')}>
                            启用
                        </Button>
                    )}
                </div>
            ),
        },
    ];
};

const QueryTable = (props) => {
    return (
        <div className='pageTables'>
            <Table
                className='pageTables'
                columns={columns(props)}
                pagination={false}
                bordered
                size='small'
                rowKey='id'
                // eslint-disable-next-line react/prop-types
                dataSource={props?.data}
            />
        </div>
    );
};
export default QueryTable;
