/* eslint-disable no-undef */
import React from 'react';
import { Table, Tag, Button } from 'antd';
import { getLable } from '../../../../utils/dict';
import { useNavigate } from 'react-router-dom';
import { urlKey } from '../../../../setting';

const columns = (dict, toMember) => {
    return [
        {
            title: '流水号',
            dataIndex: 'number',
            ellipsis: true,
            align: 'center',
            width: 150,
        },
        {
            title: '卡编号',
            dataIndex: 'voucherNum',
            ellipsis: true,
            align: 'center',
            width: 120,
        },
        {
            title: '卡名称',
            dataIndex: 'voucherName',
            ellipsis: true,
            width: 120,
        },
        {
            title: '会员',
            dataIndex: 'memberName',
            ellipsis: true,
            width: 120,
            render: (memberName, record) => (
                <span>
                    <Button type='link' size={'small'} onClick={() => toMember(record.memberId)}>
                        {memberName}
                    </Button>
                </span>
            ),
        },
        {
            title: '充值账户',
            dataIndex: 'memberMobile',
            align: 'center',
            ellipsis: true,
            width: 120,
        },
        {
            title: '类型',
            dataIndex: 'voucherType',
            align: 'center',
            width: 110,
            render: (voucherType) => (
                <span>
                    <Tag color='processing' key={voucherType}>
                        {getLable(voucherType, dict.VOUCHER_TYPE)}
                    </Tag>
                </span>
            ),
        },
        {
            title: '面额',
            dataIndex: 'voucherAmount',
            ellipsis: true,
            align: 'center',
            width: 120,
            render: (voucherAmount, record) => (
                <span>
                    {voucherAmount +
                        ' ' +
                        (record?.voucherUnit ? getLable(record?.voucherUnit, dict.VOUCHER_UNIT) : '')}
                </span>
            ),
        },

        {
            title: '状态',
            dataIndex: 'state',
            align: 'center',
            width: 80,
            render: (state) => (
                <span>
                    <Tag color={state == '1' ? 'success' : 'error'} key={state}>
                        {getLable(state, dict.VOUCHER_STATE)}
                    </Tag>
                </span>
            ),
        },

        {
            title: '充值日期',
            dataIndex: 'createTime',
            ellipsis: true,
            align: 'center',
            width: 150,
        },
    ];
};

const QueryTable = (props) => {
    const navigate = useNavigate();
    const toMember = (id) => {
        navigate(urlKey + 'member/detail', { state: { id } });
    };

    return (
        <div className='pageTables'>
            <Table
                className='pageTables'
                // eslint-disable-next-line react/prop-types
                columns={columns(props?.dict, toMember)}
                pagination={false}
                bordered
                scrollTo={{ x: 1500 }}
                size='small'
                rowKey='id'
                // eslint-disable-next-line react/prop-types
                dataSource={props?.data}
            />
        </div>
    );
};
export default QueryTable;
