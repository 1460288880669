import { useState, useEffect } from 'react';
import useFetch from '../Request';
/**
 * 用来进行数据列表渲染
 * @param {*} url  请求url
 * @param {*} shouldFetch  是否请求
 * @param {*} method  请求方式  get、post
 * @param {*} param  请求参数
 * @param {*} body  请求体  param 和body 后端接收方式不一样
 * @returns
 */

function useDict(dictNames) {
    const [dictList, setDictList] = useState([]);
    const [response, setResponse] = useState({});
    useFetch('dict/getDict', true, 'post', { dictStr: dictNames }, null, setResponse);
    useEffect(() => {
        setDictList(response?.data);
    }, [response]);

    return dictList;
}

export default useDict;
