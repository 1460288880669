import md5 from 'js-md5';
import CryptoJS from 'crypto-js';
import { CryptoJS_KEY } from '../setting';
// ------------AES-------------

function getAesString(data) {
    var encrypted = CryptoJS.AES.encrypt(data, CryptoJS_KEY.key).toString(); //传参为加密内容及秘钥

    // debugger
    return encrypted; //加密完成后，转换成字符串
}

function getDAesString(encrypted) {
    //第四步在需要把上面的加密块解密的业务逻辑，调用AES解密，注意key必须相同
    var decrypted = CryptoJS.AES.decrypt(encrypted, CryptoJS_KEY.key); //传参为加密后的内容及秘钥
    return decrypted.toString(CryptoJS.enc.Utf8);
}

// AES 对称秘钥加密
const aes = {
    en: (data) => getAesString(data),
    de: (data) => getDAesString(data),
};
// BASE64
const base64 = {
    en: (data) => CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data)),
    de: (data) => CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8),
};
// SHA256
const sha256 = (data) => {
    return CryptoJS.SHA256(data).toString();
};

const salt = '20240707';

export function getPass(inputPass) {
    inputPass = '$@2%' + inputPass;
    let length = inputPass.length;
    let str = inputPass;
    let publicKey = md5('PUBLIC_KEY').toUpperCase();
    const step = 8;
    if (length < 6 || step == 0) {
        str = publicKey.charAt(10) + salt.charAt(0) + salt.charAt(2) + inputPass + salt.charAt(5) + salt.charAt(4);
    }

    if (length >= 6) {
        str =
            publicKey.charAt(step % 32) +
            salt.charAt(0) +
            inputPass.substring(0, 6) +
            salt.charAt(2) +
            publicKey.charAt((step % 32) % 2) +
            inputPass.substring(6, length) +
            salt.charAt(5) +
            salt.charAt(4) +
            publicKey.charAt(step % 32);
    }
    return md5(str).toUpperCase();
}

export { aes, sha256, base64 };
