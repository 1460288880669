/* eslint-disable react/prop-types */
import React from 'react';
import { Space, Button } from 'antd';

const ActionButton = ({ handlerAdd }) => {
    return (
        <div style={{ marginBottom: '20px' }}>
            <Space>
                <Button type='primary' ghost onClick={handlerAdd}>
                    创建订单
                </Button>
            </Space>
        </div>
    );
};

export default ActionButton;
