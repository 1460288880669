import request from '../utils/request';
import { API_URL } from '../utils/request';

export function deleteInfo(body) {
    return request(API_URL + 'admin/deleteUser', 'POST', null, { params: body });
}

export function editInfo(body) {
    return request(API_URL + 'admin/updateUser', 'POST', body);
}

export function addInfo(body) {
    return request(API_URL + 'admin/insertUser', 'POST', body);
}
export function updatePass(body) {
    return request(API_URL + 'admin/updatePassByUser', 'POST', body);
}

export function getRamdonPass(body) {
    return request(API_URL + 'admin/generatorUserPass', 'POST', null, { params: body });
}
