import request from '../utils/request';
import { API_URL } from '../utils/request';

export function deleteInfo(body) {
    return request(API_URL + 'member/deleteUser', 'POST', null, { params: body });
}

export function editInfo(body) {
    return request(API_URL + 'member/updateUser', 'POST', body);
}

export function addInfo(body) {
    return request(API_URL + 'member/insertUser', 'POST', body);
}
export function queryMemberById(body) {
    return request(API_URL + 'member/queryUserById', 'POST', null, { params: body });
}

export function queryMember(body) {
    return request(API_URL + 'member/queryUserByNumber', 'POST', null, { params: body });
}

export function updatePassByUser(body) {
    return request(API_URL + 'member/updatePassByUser', 'POST', body);
}

export function getRamdonPass(body) {
    return request(API_URL + 'member/generatorUserPass', 'POST', null, { params: body });
}

export function updateInterest(body) {
    return request(API_URL + 'memberInterest/updateInterest', 'POST', null, { params: body });
}

export function insertInterest(body) {
    return request(API_URL + 'memberInterest/insertInterest', 'POST', null, { params: body });
}

export function removeInterest(body) {
    return request(API_URL + 'memberInterest/deleteInterest', 'POST', null, { params: body });
}

export function topUpRecord(body) {
    return request(API_URL + 'memberLoyaty/insertInfo', 'POST', body);
}
