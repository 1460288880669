/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Breadcrumb } from 'antd';

const getItem = (title) => {
    return { title };
};

const BreadcrumbTitle = (props) => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        let list = props?.list;
        let data = [];
        list.map((item) => {
            data.push(getItem(item));
        });

        setItems(data);
    }, props?.list);

    return (
        <div style={{ marginBottom: '10px' }}>
            <Breadcrumb items={items} />
        </div>
    );
};

export default BreadcrumbTitle;
