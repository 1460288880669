/* eslint-disable no-undef */
import React from 'react';
import { Table, Tag, Button } from 'antd';
import { getLable } from '../../../../utils/dict';

const columns = ({ updatePass, edit, dict }) => {
    return [
        {
            title: 'ID',
            dataIndex: 'id',
            width: 100,
        },
        {
            title: '账号',
            dataIndex: 'userNumber',
            ellipsis: true,
            width: 120,
        },
        {
            title: '名称',
            dataIndex: 'userName',
            ellipsis: true,
            width: 120,
        },
        {
            title: '状态',
            dataIndex: 'state',
            align: 'center',
            width: 80,
            render: (state) => (
                <span>
                    <Tag color={state == 1 ? 'success' : 'warning'} key={state}>
                        {getLable(state, dict.STATE)}
                    </Tag>
                </span>
            ),
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            align: 'center',
            width: 150,
        },
        {
            title: '更新时间',
            dataIndex: 'updateTime',
            align: 'center',
            width: 150,
        },
        {
            title: '操作',
            align: 'center',
            width: 150,
            render: (_, record) => (
                <div>
                    <Button type='link' size={'small'} onClick={() => edit(record)}>
                        编辑
                    </Button>
                    <Button type='link' size={'small'} onClick={() => updatePass(record)}>
                        重置密码
                    </Button>
                </div>
            ),
        },
    ];
};

const QueryTable = (props) => {
    return (
        <div className='pageTables'>
            <Table
                className='pageTables'
                columns={columns(props)}
                pagination={false}
                bordered
                size='small'
                rowKey='id'
                // eslint-disable-next-line react/prop-types
                dataSource={props?.data}
            />
        </div>
    );
};
export default QueryTable;
