//utils/getRoute.jsx
import { Suspense } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { getIcon } from '../routes/iconMap';
import { isBlank } from './string';

export const getRoute = (routes) => {
    return routes.map((item) => {
        return (
            <Route
                path={item.path}
                key={item.path}
                element={
                    item.redirect && !item.children ? (
                        <Navigate to={item.redirect} />
                    ) : (
                        <Suspense fallback={<div>Loading...</div>}>{item.element}</Suspense>
                    )
                }>
                {item.redirect && item.children ? (
                    <Route path={item.path} element={<Navigate to={item.redirect} />}></Route>
                ) : (
                    ''
                )}
                {item.children && item.children.length ? getRoute(item.children) : ''}
            </Route>
        );
    });
};

const getItem = (label, key, number, children, ishidden) => {
    let icon = getIcon(number);
    key = isBlank(key) ? number : key;
    return {
        key,
        icon,
        children,
        label,
        number,
        ishidden,
    };
};

export const getMenuList = (routes) => {
    let menuList = [];
    routes.map((item) => {
        if (item.isHidden == '0') {
            menuList.push(
                getItem(
                    item.name,
                    item.url,
                    item.number,
                    item.children && item.children.length ? getMenuList(item.children) : '',
                    item.isHidden,
                ),
            );
        }
    });
    return menuList;
};

export const getUrlList = (routes) => {
    let urlList = [];
    if (!routes) {
        return [];
    }
    routes.map((item) => {
        if (item.children && item.children.length) {
            urlList.push(...getUrlList(item.children));
        } else {
            if (!isBlank(item.url)) {
                urlList.push(item.url);
            }
        }
    });
    return urlList;
};

export const getRouteList = (routes) => {
    let urlList = [];
    if (!routes) {
        return [];
    }
    routes.map((item) => {
        if (!isBlank(item.path)) {
            urlList.push(item.path);
        }
    });
    return urlList;
};
