import request from '../utils/request';
import { API_URL } from '../utils/request';

export function editInfo(body) {
    return request(API_URL + 'memberLevel/updateLevel', 'POST', body);
}

export function addInfo(body) {
    return request(API_URL + 'memberLevel/insertLevel', 'POST', body);
}
