import React from 'react';
import { Space } from 'antd';
// import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
// import HeaderSearch from './component/HeaderSearch';
import UserCenter from './component/UserCenter';

import './index.css';

/**
 * @function  菜单权限
 * @desc     根据条件，生成权限按钮
 **/
const NavBar = () => {
    // const [collapsed, setCollapsed] = useState(false);
    // // const toggleCollapsed = () => {
    // //     setCollapsed(!collapsed);
    // //     // eslint-disable-next-line react/prop-types
    // //     props?.setCollapsed(!collapsed);
    // // };

    return (
        <>
            <header className='headerAll'>
                {/* <div>
                    <div className='sysTitleLogo'>{!collapsed ? 'EBook-Space' : 'E-S'}</div>
                </div> */}
                <div>
                    <Space size='large'>
                        <div className='menuButton' style={{ textAlign: 'left', width: '40px' }}>
                            {/* <Button block onClick={toggleCollapsed}>
                                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                            </Button> */}
                        </div>
                        <div className='searchInput'>{/* <HeaderSearch /> */}</div>
                    </Space>
                </div>
                <div>
                    <Space size='large'>
                        <div>
                            <UserCenter />
                        </div>
                    </Space>
                </div>
            </header>
        </>
    );
};

export default NavBar;
