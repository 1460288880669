/* eslint-disable react/prop-types */
import React from 'react';
import { Space } from 'antd';

const ActionButton = () => {
    return (
        <div style={{ marginBottom: '20px' }}>
            <Space></Space>
        </div>
    );
};

export default ActionButton;
