/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    InputNumber,
    Row,
    Radio,
    Space,
    Popconfirm,
    message,
    Checkbox,
    Select,
} from 'antd';
import { useEffect } from 'react';
import { getOptions } from '../../../../utils/dict';
import { editInfo, addInfo } from '../../../../api/book';
import { isNotBlank } from '../../../../utils/string';

const OptionModel = (props) => {
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState(null);
    const [form] = Form.useForm();
    const [title, setTitle] = useState(null);
    const [likes, setLikes] = useState([]);
    const [restictionType, setRestictionType] = useState([]);
    useEffect(() => {
        let { record, open, title, dict } = props;
        if (record) {
            if (isNotBlank(record.categories)) {
                record.categories1 = record.categories.split(',');
            }
            if (isNotBlank(record.categories)) {
                record.restictionType1 = record.restictionType.split(',');
            }
            setValues(record);
            setTitle(title);
            setOpen(open);
            getLikes(dict.BOOK_INTEREST);
            getRestictionType(dict.RESTICTION_TYPE);
        }
    }, [props]);

    const getLikes = (data) => {
        if (data == null || data.length == 0) {
            return;
        }
        let list = [];
        for (let i in data) {
            let obj = {
                value: data[i].dictValue,
                label: data[i].dictLabel,
            };
            list.push(obj);
        }
        setLikes(list);
    };
    const getRestictionType = (data) => {
        if (data == null || data.length == 0) {
            return;
        }
        let list = [];
        for (let i in data) {
            let obj = {
                value: data[i].dictValue,
                label: data[i].dictLabel,
            };
            list.push(obj);
        }
        setRestictionType(list);
    };

    const onClose = () => {
        form.resetFields();
        setValues(null);
        setOpen(false);
        form.destroyOnClose = true;
        props?.setOpen(false);
    };

    const saveOption = async (val) => {
        let resp = null;
        let like = val.categories1;
        val.categories = like.join(',');
        let restictionType = val.restictionType1;
        val.restictionType = restictionType.join(',');
        if (title == '新增') {
            resp = await addInfo(val);
        } else {
            resp = await editInfo(val);
        }
        if (resp?.code == '200') {
            setOpen(false);
            message.success(resp?.message);
            setValues({});
            props?.setOption();
            props?.setOpen(false);
        } else {
            setOpen(true);
            message.warning(resp?.message);
        }
    };

    const onFinsh = () => {
        form.validateFields()
            .then((val) => {
                saveOption({ ...values, ...val });
            })
            .catch((err) => {
                // 验证不通过时进入
                console.log(err);
            });
    };

    return (
        <>
            <Drawer
                title={title}
                width={680}
                onClose={onClose}
                open={open}
                destroyOnClose={true}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>取消</Button>
                        <Popconfirm
                            title='确定要保存吗？'
                            placement='topRight'
                            okText='确定'
                            cancelText='取消'
                            onConfirm={onFinsh}>
                            <Button htmlType='submit' type='primary'>
                                保存
                            </Button>
                        </Popconfirm>
                    </Space>
                }>
                <Form initialValues={values} form={form} preserve={false} layout='vertical'>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name='isbnNumber'
                                label='ISBN编号'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入ISBN编号',
                                    },
                                ]}>
                                <Input style={{ width: '220px' }} placeholder='请输入ISBN编号' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='bookName'
                                label='书籍名称'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入书籍名称',
                                    },
                                ]}>
                                <Input style={{ width: '220px' }} placeholder='请输入名称' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name='bookWriter'
                                label='作者'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入作者',
                                    },
                                ]}>
                                <Input style={{ width: '220px' }} placeholder='请输入作者' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='state'
                                label='状态'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择状态',
                                    },
                                ]}>
                                <Radio.Group
                                    style={{ width: '220px' }}
                                    options={props?.dict && getOptions(props?.dict.BOOK_STATE)}></Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name='fileType'
                                label='文件格式'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择文件格式',
                                    },
                                ]}>
                                <Select
                                    style={{ width: '220px' }}
                                    options={props?.dict && getOptions(props?.dict.BOOK_FILE_TYPE)}></Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='fileSize' label='文件大小(MB)'>
                                <InputNumber style={{ width: 220 }} min={0} step='0.01' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name='pubPrice'
                                label='发行价(元)'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入发行价(元)',
                                    },
                                ]}>
                                <InputNumber style={{ width: 220 }} min={0} step='0.01' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='bookPrice'
                                label='E书价(e币)'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入E书价(e币)',
                                    },
                                ]}>
                                <InputNumber style={{ width: 220 }} min={0} step='0.01' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name='pubYear'
                                label='发行年份'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入发行年份',
                                    },
                                ]}>
                                <Input style={{ width: '220px' }} placeholder='请输入发行年份' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='bookLanguage'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入语种',
                                    },
                                ]}
                                label='语种'>
                                <Select
                                    style={{ width: '220px' }}
                                    placeholder='请选择语种'
                                    allowClear={true}
                                    options={getOptions(props?.dict?.BOOK_LANGUAGE || [])}></Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name='bookVersion' label='版本'>
                                <Input style={{ width: '220px' }} placeholder='请输入版本' />
                            </Form.Item>
                        </Col>
                        {title == '编辑' && (
                            <Col span={12}>
                                <Form.Item name='shelvingDate' label='上架日期'>
                                    <Input disabled style={{ width: '220px' }} />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name='memo' label='描述'>
                                <Input.TextArea style={{ width: '220px' }} rows={3} placeholder='请输入用户描述' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={20}>
                            <Form.Item name='categories1' label='分类'>
                                <Checkbox.Group options={likes} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={20}>
                            <Form.Item name='restictionType1' label='限制级别'>
                                <Checkbox.Group options={restictionType} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );
};
export default OptionModel;
