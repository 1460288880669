import request from '../utils/request';
import { API_URL } from '../utils/request';
import requestExport from '../utils/exportRequest';

export function editInfo(body) {
    return request(API_URL + 'voucher/updateInfo', 'POST', body);
}

export function addInfo(body) {
    return request(API_URL + 'voucher/insertInfo', 'POST', body);
}

export function topUpByMember(body) {
    return request(API_URL + 'voucher/topUpByMember', 'POST', body);
}

export function getCardByNum(body) {
    return request(API_URL + 'voucher/getCardByNum', 'POST', null, { params: body });
}

export function exportList(body) {
    return requestExport(API_URL + 'voucher/download', 'GET', null, {
        params: body,
    });
}
