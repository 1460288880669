import React from 'react';
import { Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import useFetch from '../../../hooks/Request';
import useDict from '../../../hooks/Dict';
import DataPager from '../../../components/DataPager';
import useDocumentTitle from '../../../hooks/DocumentTitle';
import BreadcrumbTitle from '../../../components/BreadcrumbTitle';

import ActionButton from './component/ActionButton';
import QueryTable from './component/QueryTable';
import QueryForm from './component/QueryForm';
import OptionModel from './component/OptionModel';
import OptionUpdatePass from './component/OptionUpdatePass';

import { defaultPage } from '../../../setting';

const DictList = () => {
    const [pageValue, setPageValue] = useState(defaultPage);
    const [formValue, setFormValue] = useState({});
    const [isQuery, setIsQuery] = useState(null);
    const [queryValue, setQueryValue] = useState(pageValue);
    const [response, setResponse] = useState({});
    const [loading, setLoading] = useState(false);
    const [record, setRecord] = useState(null);

    const [optionTitle, setOptionTitle] = useState('新增');
    const [open, setOpen] = useState(false);
    const [passOpen, setPassOpen] = useState(false);

    useFetch('admin/queryListPage', isQuery, 'post', queryValue, null, setResponse, setLoading);
    const dict = useDict('STATE');
    useDocumentTitle('管理员列表');

    useEffect(() => {
        setIsQuery(1);
    }, []);

    const handlerQuery = (val) => {
        toQuery(val, defaultPage);
        setPageValue(defaultPage);
    };

    const toQuery = (val, page) => {
        // 查询页面
        let data = {
            ...val,
            ...page,
        };
        setFormValue(val);
        setQueryValue(data);
        setIsQuery(isQuery + 1);
    };

    const handlerAdd = () => {
        setOptionTitle('新增');
        setRecord({});
        setOpen(true);
    };

    const handlerEdit = (val) => {
        setOptionTitle('编辑');
        setRecord(val);
        setOpen(true);
    };
    const updatePass = (val) => {
        setRecord(val);
        setPassOpen(true);
    };

    const pageChange = (page, size) => {
        setPageValue({ page, size });
        toQuery(formValue, { page, size });
    };

    const handlerRest = () => {
        setPageValue({ page: 1, size: pageValue.size });
        toQuery({}, { page: 1, size: pageValue.size });
    };

    const saveOption = () => {
        setRecord({});
        toQuery(formValue, pageValue);
    };
    return (
        <div className='appPage'>
            <Spin spinning={loading}>
                <BreadcrumbTitle list={['系统设置', '管理员列表']} />
                <div className='pageContent'>
                    <QueryForm dict={dict} setForm={(val) => handlerQuery(val)} restForm={handlerRest} />
                    <Row>
                        <ActionButton handlerAdd={handlerAdd} />
                    </Row>
                    <Row>
                        <QueryTable dict={dict} data={response?.data} updatePass={updatePass} edit={handlerEdit} />
                    </Row>
                    <Row>
                        <DataPager
                            total={response?.total}
                            current={pageValue?.page}
                            pageSize={pageValue?.size}
                            onChange={pageChange}
                        />
                    </Row>
                </div>
                {/* 编辑弹窗 */}
                <Row>
                    <OptionModel
                        title={optionTitle}
                        setRecord={(val) => setRecord(val)}
                        setOpen={(val) => setOpen(val)}
                        open={open}
                        dict={dict}
                        record={record}
                        setOption={saveOption}
                    />
                </Row>
                {/* 编辑弹窗 */}
                <Row>
                    <OptionUpdatePass
                        setRecord={(val) => setRecord(val)}
                        setOpen={(val) => setPassOpen(val)}
                        open={passOpen}
                        record={record}
                    />
                </Row>
            </Spin>
        </div>
    );
};

export default DictList;
