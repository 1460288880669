import { Navigate } from 'react-router-dom';
import Login from '../pages/login';
import Home from '../pages/home';
import NoFoundPage from '../pages/exception/404';
import NoFundAuth from '../pages/exception/403';
import ERROR from '../pages/exception/500';
import MemberList from '../pages/member/MemberList';
import MemberLevel from '../pages/member/MemberLevel';
import MemberDetail from '../pages/member/MemberDetail';
import DictList from '../pages/dict/DictList';
import DictDetail from '../pages/dict/DictDetail';
import AdminList from '../pages/admin/AdminList';
import VoucherList from '../pages/voucher/VoucherList';
import VoucherRecord from '../pages/voucher/VoucherRecord';
import MemberPoints from '../pages/member/MemberPoints';
import BookList from '../pages/book/BookList';
import BookDetail from '../pages/book/BookDetail';
import OrderList from '../pages/book/OrderList';
import PreBookList from '../pages/book/PreBookList';
import { urlKey } from '../setting';

const routes = [
    {
        path: urlKey + 'login',
        layout: false,
        component: Login,
        element: <Login />,
    },
    {
        path: urlKey + 'dashboard',
        title: '首页',
        component: Home,
        element: <Home />,
    },
    {
        path: urlKey + '404',
        component: NoFoundPage,
        element: <NoFoundPage />,
    },
    {
        path: urlKey + '403',
        component: NoFundAuth,
        element: <NoFundAuth />,
    },
    {
        path: urlKey + '/error',
        element: <Navigate to='/ebook-space-web/500' />,
    },
    {
        path: urlKey + '/500',
        component: ERROR,
        element: <ERROR />,
    },
    {
        path: urlKey + '',
        element: <Navigate to='/ebook-space-web/dashboard' />,
    },
    {
        path: '/',
        element: <Navigate to='/ebook-space-web/login' />,
    },
    {
        title: '管理员列表',
        path: urlKey + 'admin/list',
        component: AdminList,
        element: <AdminList />,
    },
    {
        title: '会员列表',
        path: urlKey + 'member/list',
        component: MemberList,
        element: <MemberList />,
    },
    {
        title: '会员等级',
        path: urlKey + 'member/level',
        component: MemberLevel,
        element: <MemberLevel />,
    },
    {
        title: '会员积分',
        path: urlKey + 'member/point',
        component: MemberPoints,
        element: <MemberPoints />,
    },
    {
        title: '会员详情',
        path: urlKey + 'member/detail',
        component: MemberDetail,
        element: <MemberDetail />,
    },
    {
        title: '管理员列表',
        path: urlKey + 'admin/list',
        component: AdminList,
        element: <AdminList />,
    },
    {
        title: '书籍列表',
        path: urlKey + 'book/list',
        component: BookList,
        element: <BookList />,
    },
    {
        title: '待采列表',
        path: urlKey + 'book/pre/list',
        component: PreBookList,
        element: <PreBookList />,
    },

    {
        title: '书籍详情',
        path: urlKey + 'book/detail',
        component: BookDetail,
        element: <BookDetail />,
    },
    {
        title: '充值卡列表',
        path: urlKey + 'voucher/list',
        component: VoucherList,
        element: <VoucherList />,
    },
    {
        title: '充值记录',
        path: urlKey + 'voucher/record',
        component: VoucherRecord,
        element: <VoucherRecord />,
    },
    {
        title: '订单列表',
        path: urlKey + 'order/list',
        component: OrderList,
        element: <OrderList />,
    },
    {
        title: '数据字典',
        path: urlKey + 'dict/list',
        component: DictList,
        element: <DictList />,
    },
    {
        title: '字典详情',
        path: urlKey + 'dict/detail',
        component: DictDetail,
        element: <DictDetail />,
    },
];
export default routes;
