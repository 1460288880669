export function isArray(arr) {
    return Array.isArray(arr);
}

export function isBlankArray(arr) {
    return !Array.isArray(arr) || arr.length == 0;
}

export function isNotBlankArray(arr) {
    return Array.isArray(arr) && arr.length > 0;
}

/**
 * 数组并集,只支持一维数组
 * @param {Array} arrOne
 * @param {Array} arrTwo
 */
export function arrAndSet(arrOne, arrTwo) {
    return arrOne.concat(arrTwo.filter((v) => !arrOne.includes(v)));
}

/**
 * 数组交集,只支持一维数组
 * @param {Array} arrOne
 * @param {Array} arrTwo
 */
export function arrIntersection(arrOne, arrTwo) {
    return arrOne.filter((v) => arrTwo.includes(v));
}

/**
 * 数组差集,只支持一维数组
 * @param {Array} arrOne
 * @param {Array} arrTwo
 * eg: [1, 2, 3] [2, 4, 5] 差集为[1,3,4,5]
 */
export function arrDifference(arrOne, arrTwo) {
    return arrOne.concat(arrTwo).filter((v) => !arrOne.includes(v) || !arrTwo.includes(v));
}
