import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const NoFundAuth = () => {
    const navigate = useNavigate();
    return (
        <Result
            status='403'
            title='403'
            style={{
                background: 'none',
            }}
            subTitle='对不起，您没有权限访问这个页面。'
            extra={
                <Button type='primary' onClick={() => navigate('/')}>
                    Back Home
                </Button>
            }
        />
    );
};

export default NoFundAuth;
