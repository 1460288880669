import React from 'react';
import { DownOutlined, UserOutlined, PoweroffOutlined } from '@ant-design/icons';
import { Dropdown, Space, Avatar, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getCache, removeCache, removeToken, userKey } from '../../../../utils/auth';
import { useState, useEffect } from 'react';
import { toLoginOut } from '../../../../api/auth';
import logo from '../../logo192.png';
import { urlKey } from '../../../../setting';

const UserCenter = () => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState(null);
    const [userImage, setUserImage] = useState(null);
    const loginOut = async () => {
        const resp = await toLoginOut();
        if (resp?.code == '200') {
            removeCache(userKey);
            removeToken();
            navigate(urlKey + 'login');
        } else {
            message.warning(resp?.message);
        }
    };

    useEffect(() => {
        let userInfo = getCache(userKey);
        setUserName(userInfo?.nickName || '');
        setUserImage(logo);
    }, []);

    const items = [
        {
            key: '3',
            label: (
                <a href='#' onClick={loginOut}>
                    退出登录
                </a>
            ),
            icon: <PoweroffOutlined />,
        },
    ];

    return (
        <>
            <div onClick={(e) => e.preventDefault()}>
                <Dropdown menu={{ items }} trigger={['click']}>
                    <Space>
                        <Avatar size='small' src={userImage} icon={<UserOutlined />} />
                        {userName}
                        <DownOutlined />
                    </Space>
                </Dropdown>
            </div>
        </>
    );
};
export default UserCenter;
