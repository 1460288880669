/* eslint-disable react/prop-types */
import React from 'react';
import { Space, Button } from 'antd';

const ActionButton = ({ handlerAdd, handlerTopUp, handlerExport }) => {
    return (
        <div style={{ marginBottom: '20px' }}>
            <Space>
                <Button type='primary' ghost onClick={handlerAdd}>
                    新增
                </Button>
                <Button type='primary' ghost onClick={handlerTopUp}>
                    充值
                </Button>
                <Button type='primary' ghost onClick={handlerExport}>
                    导出
                </Button>
            </Space>
        </div>
    );
};

export default ActionButton;
