import axios from 'axios';
import { message } from 'antd';
import { getToken } from './auth';

const instanceExport = axios.create({
    timeout: 5 * 60 * 1000, // 设置超时时间
    headers: {
        'Content-Type': 'application/json',
        'Request-Time': new Date().getTime(),
    },
});

instanceExport.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token) {
            config.headers['Space-Token'] = token;
        }
        config.responseType = 'blob';
        return config;
    },
    (error) => {
        console.error('Error in request interceptor:', error);
        message.error('导出请求异常');
        Promise.reject(error);
    },
);

instanceExport.interceptors.response.use(
    (response) => {
        if (response.status === 200) {
            let data = response.data;
            if (data?.code == '10010') {
                window.location.href = '/ebook-space-web/login';
            } else if (data?.code == '500') {
                message.warning(data?.message || '系统异常');
            }
            return response;
        } else {
            message.error('导出响应异常');
            throw new Error(response.data?.message);
        }
    },
    (error) => {
        console.error('Error in response interceptor:', error);
        // 处理响应错误
        // 如果响应状态码为401，可以进行一些操作，例如跳转到登录页面
        if (error.status === 401) {
            window.location.href = '/ebook-space-web/login';
        }
        return Promise.reject(error);
    },
);

export default async function requestExport(url, method, data, options) {
    const { headers, params, ...rest } = options || {};
    try {
        let res;
        switch (method.toLowerCase()) {
            case 'get':
                res = await instanceExport.get(url, { headers, params });
                break;
            case 'post':
                res = await instanceExport.post(url, data, { headers, params, ...rest });
                break;
            default:
                throw new Error(`Unsupported HTTP method: ${method}`);
        }
        return res;
    } catch (error) {
        console.log(error);
        throw error;
    }
}
