import { useEffect } from 'react';
import { BASE_TITLE } from '../../setting';

const useDocumentTitle = (title) => {
    useEffect(() => {
        document.title = title + ' - ' + BASE_TITLE;
    }, [title]);
};

export default useDocumentTitle;
