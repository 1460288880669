/* eslint-disable eqeqeq */
/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
    if (typeof str === 'string' || str instanceof String) {
        return true;
    }
    return false;
}

export function isNull(str) {
    return str == null;
}

export function isNotNull(str) {
    return !isNull(str);
}

export function isBlank(str) {
    if (str === '' || str == undefined || str == null) {
        return true;
    }
    str = str + '';
    if (str.trim() == '') {
        // 去除空格
        return true;
    }
    return false;
}

export function isNotBlank(str) {
    return !isBlank(str);
}
/**
 * 截取长度
 * @param {str}
 * @param {length} 限制的长度， 默认20
 * @return {String}
 */

export function limitLength(str, length) {
    length = length || 20;
    if (isBlank(str) || str.length <= length) {
        return str;
    }

    return str.substring(0, 21) + '...';
}

/**
 * 去除空格
 * @param {str}
 * @param {type}
 *    type: 1-所有空格 2-前后空格 3-前空格 4-后空格
 * @return {String}
 */
export function trim(str, type) {
    type = type || 1;
    switch (type) {
        case 1:
            return str.replace(/\s+/g, '');
        case 2:
            return str.replace(/(^\s*)|(\s*$)/g, '');
        case 3:
            return str.replace(/(^\s*)/g, '');
        case 4:
            return str.replace(/(\s*$)/g, '');
        default:
            return str;
    }
}

/**
 * @param {str}
 * @param {type}
 *    type: 1:首字母大写 2：首页母小写 3：大小写转换 4：全部大写 5：全部小写
 * @return {String}
 */
export function changeCase(str, type) {
    type = type || 4;
    switch (type) {
        case 1:
            return str.replace(/\b\w+\b/g, function (word) {
                return word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase();
            });
        case 2:
            return str.replace(/\b\w+\b/g, function (word) {
                return word.substring(0, 1).toLowerCase() + word.substring(1).toUpperCase();
            });
        case 3:
            return str
                .split('')
                .map(function (word) {
                    if (/[a-z]/.test(word)) {
                        return word.toUpperCase();
                    } else {
                        return word.toLowerCase();
                    }
                })
                .join('');
        case 4:
            return str.toUpperCase();
        case 5:
            return str.toLowerCase();
        default:
            return str;
    }
}

// 字符串是否为空
export function isEmpty(str) {
    return str.trim().length === 0;
}

export function isNotEmpty(str) {
    return !isEmpty(str);
}

// 字符串是否为数字
export function isNumber(str) {
    return /^\d+$/.test(str);
}

// 字符串转 JSON 字符串
export function jsonStringify(obj) {
    return JSON.stringify(obj);
}

/**
 * 获取url里的参数
 *
 * name:需要查找的参数
 */
export function getUrlPara(name) {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    const r = window.location.search.substr(1).match(reg);

    if (r != null) {
        return r[2];
    }

    return null;
}
