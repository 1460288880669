import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getMenuList } from '../../utils/route';
import { menuList } from '../../routes/menu';

import './index.css';

/**
 * @function  菜单权限
 * @desc     根据条件，生成权限按钮
 **/
const LeftMenu = (props) => {
    const [collapsed, setCollapsed] = useState(false);
    const [menuData, setMenuData] = useState([]);

    //路由跳转的函数
    const navigate = useNavigate();

    //点击每一项
    const onClick = (e) => {
        if (e?.key) {
            navigate(e.key);
        }
    };
    useEffect(() => {
        // eslint-disable-next-line react/prop-types
        setCollapsed(props?.collapsed);
    }, [props]);

    useEffect(() => {
        // 获取缓存
        setMenuData(getMenuList(menuList));
    }, []);

    return (
        <>
            <div>
                <div className='sysTitleLogo'>{!collapsed ? 'EBook-Space' : 'E-S'}</div>
                <Menu
                    defaultSelectedKeys={['/dashboard']}
                    mode='inline'
                    onClick={(val) => onClick(val)}
                    inlineCollapsed={collapsed}
                    items={menuData}
                />
            </div>
        </>
    );
};

export default LeftMenu;
