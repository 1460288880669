/* eslint-disable no-undef */
import React from 'react';
import { Table, Popconfirm, Button, Tag } from 'antd';
import { getLable } from '../../../../utils/dict';

const columns = ({ detail, edit, remove, dict }) => {
    return [
        {
            title: 'ISBN编号',
            dataIndex: 'isbnNumber',
            width: 140,
            align: 'center',
            ellipsis: true,
            // render: (text) => <a>{text}</a>,
        },
        {
            title: '书籍名称',
            dataIndex: 'bookName',
            ellipsis: true,
            width: 150,
        },
        {
            title: '作者',
            dataIndex: 'bookWriter',
            ellipsis: true,
            width: 120,
        },
        {
            title: '语种',
            dataIndex: 'bookLanguage',
            align: 'center',
            ellipsis: true,
            width: 100,
            render: (bookLanguage) => (
                <span>
                    <Tag color={'processing'} key={bookLanguage}>
                        {getLable(bookLanguage, dict?.BOOK_LANGUAGE)}
                    </Tag>
                </span>
            ),
        },
        {
            title: '发行年份',
            dataIndex: 'pubYear',
            align: 'center',
            ellipsis: true,
            width: 120,
        },

        {
            title: '状态',
            dataIndex: 'state',
            align: 'center',
            width: 80,
            render: (state) => (
                <span>
                    <Tag color={state == '1' ? 'success' : state == '-1' ? 'error' : 'warning'} key={state}>
                        {getLable(state, dict?.BOOK_STATE)}
                    </Tag>
                </span>
            ),
        },
        {
            title: '格式',
            dataIndex: 'fileType',
            ellipsis: true,
            align: 'center',
            width: 80,
            render: (fileType) => (
                <span>
                    <Tag color={'processing'} key={fileType}>
                        {getLable(fileType, dict?.BOOK_FILE_TYPE)}
                    </Tag>
                </span>
            ),
        },
        {
            title: '上架日期',
            dataIndex: 'shelvingDate',
            ellipsis: true,
            align: 'center',
            width: 120,
        },
        {
            title: '发行价(元)',
            dataIndex: 'pubPrice',
            ellipsis: true,
            align: 'center',
            width: 120,
        },
        {
            title: 'E书价(e币)',
            dataIndex: 'bookPrice',
            ellipsis: true,
            align: 'center',
            width: 120,
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            ellipsis: true,
            align: 'center',
            width: 150,
        },

        {
            title: '更新时间',
            dataIndex: 'updateTime',
            ellipsis: true,
            align: 'center',
            width: 150,
        },
        {
            title: '操作',
            width: 180,
            align: 'center',
            fixed: 'right',
            render: (_, record) => (
                <div>
                    <Button name='详情' type='link' size={'small'} onClick={() => detail(record)}>
                        详情
                    </Button>
                    <Button type='link' size={'small'} onClick={() => edit(record)}>
                        编辑
                    </Button>
                    <Popconfirm
                        title='确定要删除吗？'
                        placement='topRight'
                        okText='确定'
                        cancelText='取消'
                        onConfirm={() => remove(record.id, record.userNumber)}>
                        <Button danger type='link' size={'small'}>
                            删除
                        </Button>
                    </Popconfirm>
                </div>
            ),
        },
    ];
};

const QueryTable = (props) => {
    return (
        <div className='pageTables'>
            <Table
                className='pageTables'
                columns={columns(props)}
                pagination={false}
                scrollTo={{ x: 1500 }}
                bordered
                rowKey='id'
                size='small'
                // eslint-disable-next-line react/prop-types
                dataSource={props?.data}
            />
        </div>
    );
};
export default QueryTable;
