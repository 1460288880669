/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Col, Drawer, Form, Input, Row, Select, Space, Popconfirm, message, InputNumber, Spin } from 'antd';
import { useEffect } from 'react';
import { createOrder, queryOrder } from '../../../../api/order';
import { queryMember } from '../../../../api/member';
import { queryListByNumber } from '../../../../api/book';
import { isBlankArray } from '../../../../utils/array';
import { getOptions } from '../../../../utils/dict';

const OptionModel = (props) => {
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState(null);
    const [title, setTitle] = useState(null);
    const [form] = Form.useForm();
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        let { record, open, title } = props;
        if (record) {
            setValues(record);
            setTitle(title);
            setOpen(open);
        }
    }, [props]);

    const onClose = () => {
        form.resetFields();
        setValues(null);
        setOpen(false);
        props?.setOpen(false);
    };

    const saveOption = async (val) => {
        let params = {
            memberNumber: val.memberNumber,
            bookId: val.bookId.value,
        };
        let resp = await createOrder(params);
        if (resp?.code == '200') {
            setOpen(false);
            message.success(resp?.message);
            setOptions([]);
            setValues({});
            props?.setOption();
            props?.setOpen(false);
        } else {
            setOpen(true);
            message.warning(resp?.message);
        }
    };

    const onFinsh = () => {
        form.validateFields()
            .then((val) => {
                setOptions([]);
                saveOption({ ...val });
            })
            .catch((err) => {
                // 验证不通过时进入
                console.log(err);
            });
    };

    const getMember = async (e) => {
        console.log(e.target.value);
        let resp = await queryMember({ number: e.target.value });
        console.log(resp);
        if (resp?.code == '200') {
            let data = resp?.data;
            if (data == null) {
                message.warning('会员不存在');
            } else {
                form.setFieldValue('memberName', data.nick);
                form.setFieldValue('accountBalance', data.accountBalance);
                form.setFieldValue('accountPoints', data.accountPoints);
            }
        } else {
            message.warning(resp.message);
        }
    };

    const handleSearch = async (newValue) => {
        setOptions([]);
        setFetching(true);
        let resp = await queryListByNumber({ number: newValue, page: 1, size: 100 });
        console.log(resp);
        if (resp?.code == '200') {
            let data = resp?.data;
            if (isBlankArray(data)) {
                return;
            }
            let options = [];
            for (let i in data) {
                let obj = {
                    value: data[i].id,
                    label: data[i].bookName,
                };
                options.push(obj);
            }
            setOptions(options);
        } else {
            setFetching(false);
            message.warning(resp.message);
        }
    };

    const getBook = async (val) => {
        console.log(val);
        let memberNumber = form.getFieldValue('memberNumber');
        let resp = await queryOrder({ bookId: val.value, memberNumber: memberNumber });
        console.log(resp);
        if (resp?.code == '200') {
            let data = resp?.data;
            if (data != null) {
                form.setFieldValue('bookPrice', data.bookPrice);
                form.setFieldValue('discountRateResult', data.discountRateResult);
                form.setFieldValue('payPrice', data.payPrice);
                form.setFieldValue('pubPrice', data.pubPrice);
                form.setFieldValue('bookType', data.bookType);
            } else {
                message.warning('获取图书信息失败');
            }
        } else {
            setFetching(false);
            message.warning(resp.message);
        }
    };

    return (
        <>
            <Drawer
                title={title}
                width={680}
                onClose={onClose}
                open={open}
                destroyOnClose={true}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>取消</Button>
                        <Popconfirm
                            title='确定要提交吗？'
                            placement='topRight'
                            okText='确定'
                            cancelText='取消'
                            onConfirm={onFinsh}>
                            <Button htmlType='submit' type='primary'>
                                提交
                            </Button>
                        </Popconfirm>
                    </Space>
                }>
                <Form initialValues={values} form={form} preserve={false} layout='vertical'>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item maxLength={20} name='memberNumber' label='会员'>
                                <Input
                                    onBlur={(val) => {
                                        getMember(val);
                                    }}
                                    style={{ width: '220px' }}
                                    placeholder='请输入会员编号'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='memberName' label='会员名称'>
                                <Input
                                    maxLength={30}
                                    disabled
                                    style={{ width: '220px' }}
                                    placeholder='请输入会员名称'
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name='accountBalance' label='账户余额'>
                                <InputNumber max={999999999.99} disabled style={{ width: 220 }} min={0} step='0.01' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='accountPoints' label='账户积分'>
                                <InputNumber max={999999999.99} disabled style={{ width: 220 }} min={0} step='1' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={20}>
                            <Form.Item
                                name='bookId'
                                label='图书'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择图书',
                                    },
                                ]}>
                                <Select
                                    labelInValue
                                    showSearch
                                    maxLength={50}
                                    filterOption={false}
                                    placeholder='请输入图书名称或者ISBN编号'
                                    onSearch={handleSearch}
                                    onChange={(val) => {
                                        getBook(val);
                                    }}
                                    notFoundContent={fetching ? <Spin size='small' /> : null}
                                    options={options}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name='pubPrice' label='发行价(元e)'>
                                <InputNumber disabled style={{ width: 220 }} min={0} step='0.01' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='discountRateResult' label='折扣'>
                                <InputNumber disabled style={{ width: 220 }} min={0} step='0.01' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name='payPrice'
                                label='订阅价(e币)'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入订阅价(e币)',
                                    },
                                ]}>
                                <InputNumber disabled style={{ width: 220 }} min={0} step='1' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='bookType'
                                label='订单类型'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入订单类型',
                                    },
                                ]}>
                                <Select
                                    disabled
                                    style={{ width: '220px' }}
                                    placeholder='请选择订单类型'
                                    allowClear={true}
                                    options={getOptions(props?.dict?.ORDER_TYPE || [])}></Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );
};
export default OptionModel;
