/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    InputNumber,
    Row,
    Radio,
    Space,
    Popconfirm,
    message,
    Checkbox,
    Select,
} from 'antd';
import { useEffect } from 'react';
import { getOptions } from '../../../../utils/dict';
import { validEmail } from '../../../../utils/validate';
import { editInfo, addInfo } from '../../../../api/member';
import { isNotBlank } from '../../../../utils/string';

const OptionModel = (props) => {
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState(null);
    const [form] = Form.useForm();
    const [title, setTitle] = useState(null);
    const [levels, setLevels] = useState([]);
    const [likes, setLikes] = useState([]);
    useEffect(() => {
        let { record, open, title, levelList, dict } = props;
        if (record) {
            setValues(record);
            setTitle(title);
            setOpen(open);
            getLevels(levelList);
            setOpen(open);
            getLikes(dict.BOOK_INTEREST);
        }
    }, [props]);

    const getLevels = (data) => {
        if (data == null || data.length == 0) {
            return;
        }
        let list = [];
        for (let i in data) {
            let obj = {
                value: data[i].id,
                label: data[i].levelName,
            };
            list.push(obj);
        }
        setLevels(list);
    };

    const getLikes = (data) => {
        if (data == null || data.length == 0) {
            return;
        }
        let list = [];
        for (let i in data) {
            let obj = {
                value: data[i].dictValue,
                label: data[i].dictLabel,
            };
            list.push(obj);
        }
        setLikes(list);
    };

    const onClose = () => {
        form.resetFields();
        setValues(null);
        setOpen(false);
        form.destroyOnClose = true;
        props?.setOpen(false);
    };

    const saveOption = async (val) => {
        let resp = null;
        if (title == '新增') {
            let like = val.interests;
            if (isNotBlank(like)) {
                val.interests = like.join(',');
            }
            resp = await addInfo(val);
        } else {
            resp = await editInfo(val);
        }
        if (resp?.code == '200') {
            setOpen(false);
            message.success(resp?.message);
            setValues({});
            props?.setOption();
            props?.setOpen(false);
        } else {
            setOpen(true);
            message.warning(resp?.message);
        }
    };

    const onFinsh = () => {
        form.validateFields()
            .then((val) => {
                if (isNotBlank(val.mail) && !validEmail(val.mail)) {
                    message.warning('请输入正确的邮箱');
                    return false;
                }
                saveOption({ ...values, ...val });
            })
            .catch((err) => {
                // 验证不通过时进入
                console.log(err);
            });
    };

    return (
        <>
            <Drawer
                title={title}
                width={680}
                onClose={onClose}
                open={open}
                destroyOnClose={true}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>取消</Button>
                        <Popconfirm
                            title='确定要保存吗？'
                            placement='topRight'
                            okText='确定'
                            cancelText='取消'
                            onConfirm={onFinsh}>
                            <Button htmlType='submit' type='primary'>
                                保存
                            </Button>
                        </Popconfirm>
                    </Space>
                }>
                <Form initialValues={values} form={form} preserve={false} layout='vertical'>
                    <Row gutter={16}>
                        {title == '编辑' && (
                            <Col span={12}>
                                <Form.Item name='number' label='会员ID'>
                                    <Input style={{ width: '220px' }} disabled={title == '编辑'} />
                                </Form.Item>
                            </Col>
                        )}
                        <Col span={12}>
                            <Form.Item
                                name='mobile'
                                label='手机号'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入手机号',
                                    },
                                    {
                                        pattern: /^1[3-9]\d{9}$/,
                                        message: '请输入正确的手机号',
                                    },
                                ]}>
                                <Input maxLength={20} style={{ width: '220px' }} placeholder='请输入手机号' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name='nick'
                                label='会员名称'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入会员名称',
                                    },
                                ]}>
                                <Input maxLength={30} style={{ width: '220px' }} placeholder='请输入会员名称' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='mail' label='邮箱'>
                                <Input
                                    maxLength={50}
                                    style={{ width: '220px' }}
                                    placeholder='请输入邮箱'
                                    rules={[
                                        {
                                            type: 'email',
                                            message: '邮箱格式不正确',
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name='status'
                                label='状态'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择状态',
                                    },
                                ]}>
                                <Radio.Group
                                    style={{ width: '220px' }}
                                    options={props?.dict && getOptions(props?.dict.STATE)}></Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='title'
                                label='称呼'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择称呼',
                                    },
                                ]}>
                                <Radio.Group
                                    style={{ width: '220px' }}
                                    options={props?.dict && getOptions(props?.dict.SEX_INFO)}></Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name='accountBalance' label='账户余额'>
                                <InputNumber
                                    max={99999999999.99}
                                    style={{ width: '220px' }}
                                    min={0}
                                    defaultValue={0.0}
                                    step='0.01'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='accountPoints' label='会员积分'>
                                <InputNumber
                                    max={99999999999.99}
                                    style={{ width: '220px' }}
                                    min={0}
                                    defaultValue={0}
                                    step='1'
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name='memberLevel'
                                label='会员等级'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择会员等级',
                                    },
                                ]}>
                                <Select style={{ width: '220px' }} options={levels} />
                            </Form.Item>
                        </Col>
                        {title == '新增' && (
                            <Col span={12}>
                                <Form.Item
                                    name='password'
                                    label='密码'
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入密码',
                                        },
                                        {
                                            min: 6,
                                            message: '密码长度不能小于6位',
                                        },
                                    ]}>
                                    <Input style={{ width: '220px' }} maxLength={20} placeholder='请输入密码' />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name='birthday' label='生日'>
                                <Input maxLength={20} style={{ width: '220px' }} placeholder='请输入生日2024-01-01' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='memo' label='描述'>
                                <Input.TextArea
                                    maxLength={1000}
                                    style={{ width: '220px' }}
                                    rows={3}
                                    placeholder='请输入会员描述'
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {title == '新增' && (
                        <Row gutter={16}>
                            <Col span={20}>
                                <Form.Item name='interests' label='兴趣'>
                                    <Checkbox.Group options={likes} />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                </Form>
            </Drawer>
        </>
    );
};
export default OptionModel;
