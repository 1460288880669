import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import LeftMenu from './LeftMenu';
import NavBar from './Top';

import './index.css';

const Layout = (props) => {
    const [collapsed, setCollapsed] = useState(false);
    const [widthAll] = useState(window.innerWidth);
    const handlerCollapsed = (val) => {
        setCollapsed(val);
        // eslint-disable-next-line react/prop-types
        props?.setWidht(val);
    };

    return (
        <div className='layout-wrap'>
            <div className='left-menu' style={{ height: window.innerHeight }}>
                <LeftMenu collapsed={collapsed} setCollapsed={(val) => handlerCollapsed(val)} />
            </div>

            <div className='right-cont'>
                <div
                    className='topNav'
                    style={{
                        left: collapsed ? '90px' : '218px',
                        width: collapsed ? widthAll - 90 + 'px' : widthAll - 218 + 'px',
                    }}>
                    <NavBar collapsed={collapsed} setCollapsed={(val) => handlerCollapsed(val)} />
                </div>
                <div className='right-main'>
                    {/* 二级路由出口 */}
                    <Outlet />
                </div>
            </div>
        </div>
    );
};
export default Layout;
