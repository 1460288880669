import React from 'react';
import { Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import useFetch from '../../../hooks/Request';
import useDict from '../../../hooks/Dict';
import DataPager from '../../../components/DataPager';
import useDocumentTitle from '../../../hooks/DocumentTitle';
import BreadcrumbTitle from '../../../components/BreadcrumbTitle';
import { isNotBlankArray } from '../../../utils/array';
import moment from 'moment';

import ActionButton from './component/ActionButton';
import QueryTable from './component/QueryTable';
import QueryForm from './component/QueryForm';

import { defaultPage } from '../../../setting';

const VoucherRecord = () => {
    const [pageValue, setPageValue] = useState(defaultPage);
    const [formValue, setFormValue] = useState({});
    const [isQuery, setIsQuery] = useState(null);
    const [queryValue, setQueryValue] = useState(pageValue);
    const [response, setResponse] = useState({});
    const [loading, setLoading] = useState(false);

    useFetch('voucherRecord/queryListPage', isQuery, 'post', queryValue, null, setResponse, setLoading);
    const dict = useDict('VOUCHER_STATE,VOUCHER_TYPE,VOUCHER_UNIT');
    useDocumentTitle('充值记录');

    useEffect(() => {
        setIsQuery(1);
    }, []);

    const handlerQuery = (val) => {
        toQuery(val, defaultPage);
        setPageValue(defaultPage);
    };

    const toQuery = (val, page) => {
        console.log(val.createTime);
        console.log(val);
        console.log(1);
        if (isNotBlankArray(val.createTime)) {
            let timeArr = val.createTime;
            val.createTime = null;
            val.startTime = moment(timeArr[0].$d).format('YYYY-MM-DD HH:mm:ss');
            val.endTime = moment(timeArr[1].$d).format('YYYY-MM-DD HH:mm:ss');
        }
        // 查询页面
        let data = {
            ...val,
            ...page,
        };
        setFormValue(val);
        setQueryValue(data);
        setIsQuery(isQuery + 1);
    };

    const pageChange = (page, size) => {
        setPageValue({ page, size });
        toQuery(formValue, { page, size });
    };

    const handlerRest = () => {
        setPageValue({ page: 1, size: pageValue.size });
        toQuery({}, { page: 1, size: pageValue.size });
    };

    return (
        <div className='appPage'>
            <Spin spinning={loading}>
                <BreadcrumbTitle list={['充值管理', '充值记录']} />
                <div className='pageContent'>
                    <QueryForm dict={dict} setForm={(val) => handlerQuery(val)} restForm={handlerRest} />
                    <Row>
                        <ActionButton />
                    </Row>
                    <Row>
                        <QueryTable dict={dict} data={response?.data} />
                    </Row>
                    <Row>
                        <DataPager
                            total={response?.total}
                            current={pageValue?.page}
                            pageSize={pageValue?.size}
                            onChange={pageChange}
                        />
                    </Row>
                </div>
            </Spin>
        </div>
    );
};

export default VoucherRecord;
