/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Row, Col, DatePicker, Space } from 'antd';
import { getOptions, getLevelOptions } from '../../../../utils/dict';
import { DownOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';
dayjs.locale('zh-cn');
const { RangePicker } = DatePicker;

const QueryForm = (props) => {
    const [form] = Form.useForm();
    const [expand, setExpand] = useState(false);
    const [dict, setDict] = useState([]);
    const [levelList, setLevelList] = useState([]);

    const onFinish = (values) => {
        // eslint-disable-next-line react/prop-types
        props?.setForm(values);
    };
    const noExpandField = [
        <>
            <Col span={6} key={1}>
                <Form.Item name='number' label='会员编号'>
                    <Input style={{ width: '200px' }} allowClear placeholder='请输入会员编号' />
                </Form.Item>
            </Col>
            <Col span={6} key={2}>
                <Form.Item name='mobile' label='会员手机号'>
                    <Input style={{ width: '200px' }} allowClear placeholder='请输入会员手机号' />
                </Form.Item>
            </Col>
            <Col span={6} key={3}>
                <Form.Item name='nick' label='会员名称'>
                    <Input style={{ width: '200px' }} allowClear placeholder='请输入会员名称' />
                </Form.Item>
            </Col>
            <Col span={6} key={4}>
                <Form.Item name='status' label='状态'>
                    <Select
                        style={{ width: '200px' }}
                        placeholder='请选择状态'
                        allowClear={true}
                        options={getOptions(dict?.STATE || [])}></Select>
                </Form.Item>
            </Col>
        </>,
    ];
    const expandField = [
        <>
            <Col span={6} key={1}>
                <Form.Item name='number' label='会员编号'>
                    <Input style={{ width: '200px' }} allowClear placeholder='请输入会员编号' />
                </Form.Item>
            </Col>
            <Col span={6} key={2}>
                <Form.Item name='mobile' label='会员账户'>
                    <Input style={{ width: '200px' }} allowClear placeholder='请输入会员账户' />
                </Form.Item>
            </Col>
            <Col span={6} key={3}>
                <Form.Item name='nick' label='昵称'>
                    <Input style={{ width: '200px' }} allowClear placeholder='请输入昵称' />
                </Form.Item>
            </Col>
            <Col span={6} key={4}>
                <Form.Item name='status' label='状态'>
                    <Select
                        style={{ width: '200px' }}
                        placeholder='请选择状态'
                        allowClear={true}
                        options={getOptions(dict?.STATE || [])}></Select>
                </Form.Item>
            </Col>
            <Col span={6} key={5}>
                <Form.Item name='memberLevel' label='会员等级'>
                    <Select
                        style={{ width: '200px' }}
                        placeholder='请选择会员等级'
                        allowClear={true}
                        options={getLevelOptions(levelList || [])}></Select>
                </Form.Item>
            </Col>
            <Col span={10} key={7}>
                <Form.Item name='createTime' label='更新时间'>
                    <RangePicker
                        showTime={{
                            hideDisabledOptions: true,
                            defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')],
                        }}
                        format='YYYY-MM-DD HH:mm:ss'
                    />
                </Form.Item>
            </Col>
        </>,
    ];

    const getFields = () => {
        return expand ? expandField : noExpandField;
    };

    useEffect(() => {
        if (props?.dict) {
            setDict(props?.dict);
        }
    }, [props?.dict]);
    useEffect(() => {
        if (props?.levelList) {
            setLevelList(props?.levelList);
        }
    }, [props?.levelList]);

    return (
        <div style={{ marginBottom: '0px' }}>
            <Form form={form} onFinish={onFinish}>
                <Row gutter={24}>{getFields()}</Row>
                <div
                    style={{
                        textAlign: 'right',
                        height: '30px',
                    }}>
                    <Space size='small'>
                        <Form.Item>
                            <Button type='primary' htmlType='submit'>
                                查询
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button type='primary' onClick={props?.restForm}>
                                重置
                            </Button>
                        </Form.Item>
                        <a
                            style={{
                                fontSize: 12,
                            }}
                            onClick={() => {
                                setExpand(!expand);
                            }}>
                            <DownOutlined rotate={expand ? 180 : 0} /> {expand ? '收起' : '展开'}
                        </a>
                    </Space>
                </div>
            </Form>
        </div>
    );
};

export default QueryForm;
