/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Col, Row, DatePicker, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { getOptions } from '../../../../utils/dict';

import dayjs from 'dayjs';
dayjs.locale('zh-cn');
const { RangePicker } = DatePicker;
const QueryForm = (props) => {
    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();
    const [dict, setDict] = useState([]);
    const onFinish = (values) => {
        props?.setForm(values);
    };
    const noExpandField = [
        <>
            <Col span={6} key={1}>
                <Form.Item name='number' label='充值卡编号'>
                    <Input style={{ width: '200px' }} allowClear placeholder='请输入充值卡编号' />
                </Form.Item>
            </Col>
            <Col span={6} key={2}>
                <Form.Item name='name' label='充值卡名称'>
                    <Input style={{ width: '200px' }} allowClear placeholder='请输入充值卡名称' />
                </Form.Item>
            </Col>
            <Col span={6} key={3}>
                <Form.Item name='type' label='类型'>
                    <Select
                        style={{ width: '200px' }}
                        placeholder='请选择类型'
                        allowClear={true}
                        options={getOptions(dict?.VOUCHER_TYPE || [])}></Select>
                </Form.Item>
            </Col>
            <Col span={6} key={4}>
                <Form.Item name='state' label='状态'>
                    <Select
                        style={{ width: '200px' }}
                        placeholder='请选择状态'
                        allowClear={true}
                        options={getOptions(dict?.VOUCHER_CARD_STATE || [])}></Select>
                </Form.Item>
            </Col>
        </>,
    ];
    const expandField = [
        <>
            <Col span={6} key={1}>
                <Form.Item name='number' label='充值卡编号'>
                    <Input style={{ width: '200px' }} allowClear placeholder='请输入充值卡编号' />
                </Form.Item>
            </Col>
            <Col span={6} key={2}>
                <Form.Item name='name' label='充值卡名称'>
                    <Input style={{ width: '200px' }} allowClear placeholder='请输入充值卡名称' />
                </Form.Item>
            </Col>
            <Col span={6} key={3}>
                <Form.Item name='type' label='类型'>
                    <Select
                        style={{ width: '200px' }}
                        placeholder='请选择类型'
                        allowClear={true}
                        options={getOptions(dict?.VOUCHER_TYPE || [])}></Select>
                </Form.Item>
            </Col>
            <Col span={6} key={4}>
                <Form.Item name='state' label='状态'>
                    <Select
                        style={{ width: '200px' }}
                        placeholder='请选择状态'
                        allowClear={true}
                        options={getOptions(dict?.VOUCHER_CARD_STATE || [])}></Select>
                </Form.Item>
            </Col>
            <Col span={12} key={5}>
                <Form.Item name='createTime' label='创建时间'>
                    <RangePicker
                        showTime={{
                            hideDisabledOptions: true,
                            defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')],
                        }}
                        format='YYYY-MM-DD HH:mm:ss'
                    />
                </Form.Item>
            </Col>
        </>,
    ];
    useEffect(() => {
        if (props?.dict) {
            setDict(props?.dict);
        }
    }, [props?.dict]);
    const rest = () => {
        form.resetFields();
        props?.restForm();
    };
    const getFields = () => {
        return expand ? expandField : noExpandField;
    };
    return (
        <div style={{ marginBottom: '0px' }}>
            <Form form={form} onFinish={onFinish}>
                <Row gutter={24}>{getFields()}</Row>
                <div
                    style={{
                        textAlign: 'right',
                        height: '30px',
                    }}>
                    <Space size='small'>
                        <Form.Item>
                            <Button type='primary' htmlType='submit'>
                                查询
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button type='primary' onClick={rest}>
                                重置
                            </Button>
                        </Form.Item>
                        <a
                            style={{
                                fontSize: 12,
                            }}
                            onClick={() => {
                                setExpand(!expand);
                            }}>
                            <DownOutlined rotate={expand ? 180 : 0} /> {expand ? '收起' : '展开'}
                        </a>
                    </Space>
                </div>
            </Form>
        </div>
    );
};

export default QueryForm;
