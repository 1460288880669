/* eslint-disable no-undef */
import React from 'react';
import { Table, Tag, Button } from 'antd';
import { getLable } from '../../../../utils/dict';

const columns = ({ edit, dict }) => {
    return [
        {
            title: 'ID',
            dataIndex: 'id',
            width: 100,
        },
        {
            title: '字典编号',
            dataIndex: 'dictName',
            ellipsis: true,
            width: 120,
        },
        {
            title: '数据项-值',
            dataIndex: 'dictValue',
            ellipsis: true,
            width: 120,
        },
        {
            title: '数据项-名称',
            dataIndex: 'dictLabel',
            ellipsis: true,
            width: 120,
        },
        {
            title: '状态',
            dataIndex: 'dictState',
            align: 'center',
            width: 80,
            render: (dictState) => (
                <span>
                    <Tag color={dictState == 1 ? 'success' : 'warning'} key={dictState}>
                        {getLable(dictState, dict.STATE)}
                    </Tag>
                </span>
            ),
        },
        {
            title: '排序',
            dataIndex: 'sort',
            align: 'center',
            width: 80,
        },
        {
            title: '描述',
            dataIndex: 'memo',
            width: 150,
            ellipsis: true,
        },

        {
            title: '操作',
            width: 120,
            align: 'center',
            render: (_, record) => (
                <div>
                    <Button type='link' size={'small'} onClick={() => edit(record)}>
                        编辑
                    </Button>
                </div>
            ),
        },
    ];
};

const QueryTable = (props) => {
    return (
        <div className='pageTables'>
            <Table
                className='pageTables'
                columns={columns(props)}
                pagination={false}
                bordered
                size='small'
                rowKey='id'
                // eslint-disable-next-line react/prop-types
                dataSource={props?.data}
            />
        </div>
    );
};
export default QueryTable;
