import React from 'react';
import { message, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import useFetch from '../../../hooks/Request';
import useDict from '../../../hooks/Dict';
import DataPager from '../../../components/DataPager';
import useDocumentTitle from '../../../hooks/DocumentTitle';
import { deleteInfo } from '../../../api/member';
import { useNavigate } from 'react-router-dom';
import { defaultPage, urlKey } from '../../../setting';
import BreadcrumbTitle from '../../../components/BreadcrumbTitle';
import { isNotBlankArray } from '../../../utils/array';

import ActionButton from './component/ActionButton';
import OptionModel from './component/OptionModel';
import QueryForm from './component/QueryForm';
import QueryTable from './component/QueryTable';
import moment from 'moment';

import './index.css';

const UserList = () => {
    const [pageValue, setPageValue] = useState(defaultPage);
    const [formValue, setFormValue] = useState({});
    const [isQuery, setIsQuery] = useState(null);
    const [queryValue, setQueryValue] = useState(pageValue);
    const [response, setResponse] = useState({});
    const [memberLevelList, setMemberLevelList] = useState({});
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('新增');
    useFetch('member/queryListPage', isQuery, 'post', queryValue, null, setResponse, setLoading);

    useFetch('memberLevel/queryList', 1, 'post', null, null, setMemberLevelList, setLoading);
    const dict = useDict('STATE,SEX_INFO,BOOK_INTEREST');
    useDocumentTitle('会员列表');
    const [open, setOpen] = useState(false);
    const [record, setRecord] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        setIsQuery(1);
    }, []);

    const handlerQuery = (val) => {
        setPageValue(defaultPage);
        toQuery(val, defaultPage);
    };

    const toQuery = (val, page) => {
        // 查询页面
        if (isNotBlankArray(val.createTime)) {
            let timeArr = val.createTime;
            console.log(timeArr);
            val.startTime = moment(timeArr[0].$d).format('YYYY-MM-DD HH:mm:ss');
            val.endTime = moment(timeArr[1].$d).format('YYYY-MM-DD HH:mm:ss');
            val.createTime = null;
        }
        let data = {
            ...val,
            ...page,
        };
        setFormValue(val);
        setQueryValue(data);
        setIsQuery(isQuery + 1);
    };

    const handlerDetail = (val) => {
        navigate(urlKey + 'member/detail', { state: { id: val?.id } });
    };

    const handlerEdit = (val) => {
        setRecord(val);
        setTitle('编辑');
        setOpen(true);
    };
    const handlerAdd = () => {
        setRecord({});
        console.log(1);
        setTitle('新增');
        setOpen(true);
    };

    const handlerDelete = async (id, val) => {
        setLoading(true);
        const resp = await deleteInfo({ id, userNumber: val });
        if (resp?.code == '200') {
            message.success(resp?.message);
            toQuery(formValue, pageValue);
        } else {
            message.warning(resp?.message);
        }
        setLoading(false);
    };

    const pageChange = (page, size) => {
        setPageValue({ page, size });
        toQuery(formValue, { page, size });
    };

    const handlerRest = () => {
        setPageValue({ page: 1, size: pageValue.size });
        toQuery({}, { page: 1, size: pageValue.size });
    };

    const saveOption = () => {
        toQuery(formValue, pageValue);
    };

    return (
        <div className='appPage'>
            <Spin spinning={loading}>
                <div className='pageContent'>
                    <BreadcrumbTitle list={['会员管理', '会员列表']} />
                    <QueryForm
                        dict={dict}
                        levelList={memberLevelList?.data}
                        setForm={(val) => handlerQuery(val)}
                        restForm={handlerRest}
                    />
                    <Row>
                        <ActionButton handlerAdd={handlerAdd} />
                    </Row>
                    <Row>
                        <QueryTable
                            dict={dict}
                            data={response?.data}
                            detail={handlerDetail}
                            edit={handlerEdit}
                            levelList={memberLevelList?.data}
                            remove={handlerDelete}
                        />
                    </Row>
                    <Row>
                        <DataPager
                            total={response?.total}
                            current={pageValue?.page}
                            pageSize={pageValue?.size}
                            onChange={pageChange}
                        />
                    </Row>
                    {/* 编辑弹窗 */}
                    <Row>
                        <OptionModel
                            setOpen={(val) => setOpen(val)}
                            open={open}
                            title={title}
                            dict={dict}
                            record={record}
                            levelList={memberLevelList?.data}
                            setOption={saveOption}
                        />
                    </Row>
                </div>
            </Spin>
        </div>
    );
};

export default UserList;
