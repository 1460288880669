const key = '/ebook-space-web';

export const menuList = [
    {
        name: '首页',
        url: '/ebook-space-web/dashboard',
        number: '2',
        isHidden: 0,
    },
    {
        name: '首页',
        url: key + '/',
        number: '9',
        isHidden: 1,
    },
    {
        name: '会员管理',
        url: '',
        number: '5',
        isHidden: 0,
        children: [
            {
                name: '会员列表',
                url: key + '/member/list',
                number: '501',
                isHidden: 0,
            },
            {
                name: '会员详情',
                url: key + '/member/detail',
                number: '503',
                isHidden: 1,
            },

            {
                name: '会员积分',
                url: key + '/member/point',
                number: '504',
                isHidden: 0,
            },
        ],
    },
    {
        name: '书籍管理',
        url: '',
        number: '6',
        isHidden: 0,
        children: [
            {
                name: '书籍列表',
                url: key + '/book/list',
                number: '601',
                isHidden: 0,
            },
            {
                name: '书籍详情',
                url: key + '/book/detail',
                number: '603',
                isHidden: 1,
            },
            {
                name: '订单列表',
                url: key + '/order/list',
                number: '602',
                isHidden: 0,
            },
            {
                name: '待采列表',
                url: key + '/book/pre/list',
                number: '603',
                isHidden: 0,
            },
        ],
    },
    {
        name: '充值管理',
        url: '',
        number: '7',
        isHidden: 0,
        children: [
            {
                name: '充值卡列表',
                url: key + '/voucher/list',
                number: '701',
                isHidden: 0,
            },
            {
                name: '充值记录',
                url: key + '/voucher/record',
                number: '702',
                isHidden: 0,
            },
        ],
    },
    {
        name: '系统设置',
        url: '',
        number: '1',
        isHidden: 0,
        children: [
            {
                name: '管理员列表',
                url: key + '/admin/list',
                number: '101',
                isHidden: 0,
            },
            {
                name: '会员等级',
                url: key + '/member/level',
                number: '104',
                isHidden: 0,
            },
            {
                name: '数据字典',
                url: key + '/dict/list',
                number: '102',
                isHidden: 0,
            },
            {
                name: '字典详情',
                url: key + '/dict/detail',
                number: '103',
                isHidden: 1,
            },
        ],
    },
];
