import React from 'react';
import { Row, Spin, message } from 'antd';
import { useEffect, useState } from 'react';
import useFetch from '../../../hooks/Request';
import useDict from '../../../hooks/Dict';
import DataPager from '../../../components/DataPager';
import useDocumentTitle from '../../../hooks/DocumentTitle';
import BreadcrumbTitle from '../../../components/BreadcrumbTitle';
import { isNotBlankArray } from '../../../utils/array';
import { getDate } from '../../../utils/date';
import moment from 'moment';

import ActionButton from './component/ActionButton';
import QueryTable from './component/QueryTable';
import QueryForm from './component/QueryForm';
import OptionModel from './component/OptionModel';
import OptionModelTopUp from './component/OptionModelTopUp';

import dayjs from 'dayjs';
import { editInfo, exportList } from '../../../api/voucher';
import { exportExcel } from '../../../utils/export';

import { defaultPage } from '../../../setting';

const VoucherList = () => {
    const [pageValue, setPageValue] = useState(defaultPage);
    const [formValue, setFormValue] = useState({});
    const [isQuery, setIsQuery] = useState(null);
    const [queryValue, setQueryValue] = useState(pageValue);
    const [response, setResponse] = useState({});
    const [loading, setLoading] = useState(false);
    const [record, setRecord] = useState(null);

    const [optionTitle, setOptionTitle] = useState('新增');
    const [open, setOpen] = useState(false);
    const [openTopUp, setOpenTopUp] = useState(false);

    useFetch('voucher/queryListPage', isQuery, 'post', queryValue, null, setResponse, setLoading);
    const dict = useDict('VOUCHER_CARD_STATE,VOUCHER_TYPE,MONEY_UNIT,VOUCHER_UNIT');
    useDocumentTitle('充值卡列表');

    useEffect(() => {
        setIsQuery(1);
    }, []);

    const handlerQuery = (val) => {
        toQuery(val, defaultPage);
        setPageValue(defaultPage);
    };

    const toQuery = (val, page) => {
        console.log(val.createTime);
        console.log(val);
        console.log(1);
        if (isNotBlankArray(val.createTime)) {
            let timeArr = val.createTime;
            val.createTime = null;
            val.startTime = moment(timeArr[0].$d).format('YYYY-MM-DD HH:mm:ss');
            val.endTime = moment(timeArr[1].$d).format('YYYY-MM-DD HH:mm:ss');
        }
        // 查询页面
        let data = {
            ...val,
            ...page,
        };
        setFormValue(val);
        setQueryValue(data);
        setIsQuery(isQuery + 1);
    };

    const handlerAdd = () => {
        setOptionTitle('新增');
        setRecord({});
        setOpen(true);
    };

    const handlerEdit = (val) => {
        setOptionTitle('编辑');
        val.voucherDate1 = dayjs(getDate(val.voucherDate));
        setRecord(val);
        setOpen(true);
    };

    const handlerTopUp = () => {
        setOpenTopUp(true);
    };

    const pageChange = (page, size) => {
        setPageValue({ page, size });
        toQuery(formValue, { page, size });
    };

    const handlerRest = () => {
        setPageValue({ page: 1, size: pageValue.size });
        toQuery({}, { page: 1, size: pageValue.size });
    };

    const saveOption = () => {
        setRecord({});
        toQuery(formValue, pageValue);
    };

    const handlerExport = async () => {
        setLoading(true);
        const resp = await exportList({ ...formValue });
        exportExcel(resp);
        setLoading(false);
    };

    const handlerRemove = async (val, state) => {
        val.state = state;
        let resp = await editInfo(val);
        if (resp?.code == '200') {
            setOpen(false);
            message.success(resp?.message);
        } else {
            setOpen(true);
            message.warning(resp?.message);
        }
        saveOption();
    };

    return (
        <div className='appPage'>
            <Spin spinning={loading}>
                <BreadcrumbTitle list={['充值管理', '充值卡列表']} />
                <div className='pageContent'>
                    <QueryForm dict={dict} setForm={(val) => handlerQuery(val)} restForm={handlerRest} />
                    <Row>
                        <ActionButton
                            handlerTopUp={handlerTopUp}
                            handlerExport={handlerExport}
                            handlerAdd={handlerAdd}
                        />
                    </Row>
                    <Row>
                        <QueryTable dict={dict} data={response?.data} remove={handlerRemove} edit={handlerEdit} />
                    </Row>
                    <Row>
                        <DataPager
                            total={response?.total}
                            current={pageValue?.page}
                            pageSize={pageValue?.size}
                            onChange={pageChange}
                        />
                    </Row>
                </div>
                {/* 编辑弹窗 */}
                <Row>
                    <OptionModel
                        title={optionTitle}
                        setRecord={(val) => setRecord(val)}
                        setOpen={(val) => setOpen(val)}
                        open={open}
                        dict={dict}
                        record={record}
                        setOption={saveOption}
                    />
                </Row>
                <Row>
                    <OptionModelTopUp
                        setOpen={(val) => setOpenTopUp(val)}
                        setOption={saveOption}
                        open={openTopUp}
                        dict={dict}
                    />
                </Row>
            </Spin>
        </div>
    );
};

export default VoucherList;
