/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Col, Drawer, Form, Input, Row, Radio, Space, Popconfirm, message } from 'antd';
import { useEffect } from 'react';
import { getOptions } from '../../../../utils/dict';
import { addInfo, editInfo, getRamdonPass } from '../../../../api/admin';
import { getPass } from '../../../../utils/pass';
import { isBlank } from '../../../../utils/string';
const { TextArea } = Input;

const OptionModel = (props) => {
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState(null);
    const [title, setTitle] = useState(null);
    const [form] = Form.useForm();
    useEffect(() => {
        let { record, open, title } = props;
        if (record) {
            setValues(record);
            setTitle(title);
            setOpen(open);
        }
    }, [props]);

    const onClose = () => {
        form.resetFields();
        setValues(null);
        setOpen(false);
        props?.setOpen(false);
    };

    const saveOption = async (val) => {
        let resp = null;
        let password = getPass(val.userPassword);
        val.userPassword = password;
        if (title == '新增') {
            resp = await addInfo(val);
        } else {
            resp = await editInfo(val);
        }
        if (resp?.code == '200') {
            setOpen(false);
            message.success(resp?.message);
            setValues({});
            props?.setOption();
            props?.setOpen(false);
        } else {
            setOpen(true);
            message.warning(resp?.message);
        }
    };

    const onFinsh = () => {
        form.validateFields()
            .then((val) => {
                saveOption({ ...values, ...val });
            })
            .catch((err) => {
                // 验证不通过时进入
                console.log(err);
            });
    };

    const getRandomPass = async () => {
        let userName = form.getFieldValue('userName');
        if (isBlank(userName)) {
            message.warning('请输入用户名称');
            return;
        }
        let resp = await getRamdonPass({ userName });
        if (resp?.code === '200') {
            let data = resp.data;
            form.setFieldValue('userPassword', data);
        } else {
            message.warning(resp?.message);
        }
    };

    return (
        <>
            <Drawer
                title={title}
                width={520}
                onClose={onClose}
                open={open}
                destroyOnClose={true}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>取消</Button>
                        <Popconfirm
                            title='确定要保存吗？'
                            placement='topRight'
                            okText='确定'
                            cancelText='取消'
                            onConfirm={onFinsh}>
                            <Button htmlType='submit' type='primary'>
                                保存
                            </Button>
                        </Popconfirm>
                    </Space>
                }>
                <Form initialValues={values} form={form} preserve={false}>
                    <Row gutter={16}>
                        <Col span={20}>
                            <Form.Item
                                name='userNumber'
                                label='账号'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入账号',
                                    },
                                ]}>
                                <Input allowClear maxLength={20} placeholder='请输入账号' disabled={title == '编辑'} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={20}>
                            <Form.Item
                                name='userName'
                                label='名称'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入名称',
                                    },
                                ]}>
                                <Input allowClear maxLength={20} placeholder='请输入名称' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={20}>
                            <Form.Item
                                name='state'
                                label='状态'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择状态',
                                    },
                                ]}>
                                <Radio.Group options={props?.dict && getOptions(props?.dict.STATE)}></Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    {title == '新增' && (
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name='userPassword'
                                    label='密码'
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入密码',
                                        },
                                        {
                                            min: 6,
                                            message: '密码长度不能小于6位',
                                        },
                                    ]}>
                                    <Input maxLength={20} placeholder='请输入密码' />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Button htmlType='button' onClick={getRandomPass}>
                                    随机密码
                                </Button>
                            </Col>
                        </Row>
                    )}
                    <Row gutter={16}>
                        <Col span={20}>
                            <Form.Item name='memo' label='描述'>
                                <TextArea allowClear maxLength={100} placeholder='请输入用户描述' />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );
};
export default OptionModel;
