/* eslint-disable react/prop-types */
import React from 'react';
import { Space, Button } from 'antd';

const ActionButton = ({ handlerAdd, updateCache }) => {
    return (
        <div style={{ marginBottom: '20px' }}>
            <Space>
                <Button type='primary' ghost onClick={handlerAdd}>
                    新增
                </Button>
                <Button type='primary' ghost onClick={updateCache}>
                    更新缓存
                </Button>
            </Space>
        </div>
    );
};

export default ActionButton;
