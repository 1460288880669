import { TeamOutlined, SettingOutlined, BookOutlined, CreditCardOutlined } from '@ant-design/icons';

const iconMap = new Map();

// 用户管理
iconMap.set('5', <TeamOutlined />);
iconMap.set('1', <SettingOutlined />);
iconMap.set('6', <BookOutlined />);
iconMap.set('7', <CreditCardOutlined />);

export function getIcon(number) {
    if (number) {
        return iconMap.get(number);
    }
    return null;
}
