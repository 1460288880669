/* eslint-disable no-undef */
import React from 'react';
import { Table, Tag, Button } from 'antd';
import { getLable } from '../../../../utils/dict';
import { useNavigate } from 'react-router-dom';
import { urlKey } from '../../../../setting';

const columns = (dict, toBook, toMember) => {
    return [
        {
            title: '订单编号',
            dataIndex: 'orderNum',
            ellipsis: true,
            align: 'center',
            width: 150,
        },
        {
            title: '书籍名称',
            dataIndex: 'bookName',
            ellipsis: true,
            width: 150,
            render: (bookName, record) => (
                <span>
                    <Button type='link' size={'small'} onClick={() => toBook(record.bookId)}>
                        {bookName}
                    </Button>
                </span>
            ),
        },
        {
            title: '书籍编号',
            dataIndex: 'bookNumber',
            ellipsis: true,
            align: 'center',
            width: 120,
        },
        {
            title: '会员名称',
            dataIndex: 'memberName',
            ellipsis: true,
            width: 120,
            render: (memberName, record) => (
                <span>
                    <Button type='link' size={'small'} onClick={() => toMember(record.memberId)}>
                        {memberName}
                    </Button>
                </span>
            ),
        },
        {
            title: '会员编号',
            dataIndex: 'memberNumber',
            align: 'center',
            ellipsis: true,
            width: 120,
        },
        {
            title: '会员手机号',
            dataIndex: 'memberMobile',
            align: 'center',
            ellipsis: true,
            width: 120,
        },
        {
            title: '订单类型',
            dataIndex: 'orderType',
            align: 'center',
            width: 110,
            render: (orderType) => (
                <span>
                    <Tag color={orderType == '1' ? 'success' : 'warning'} key={orderType}>
                        {getLable(orderType, dict.ORDER_TYPE)}
                    </Tag>
                </span>
            ),
        },

        {
            title: '发行价(元)',
            dataIndex: 'bookPrice',
            ellipsis: true,
            align: 'center',
            width: 120,
        },
        {
            title: '订阅价(e币)',
            dataIndex: 'payPrice',
            ellipsis: true,
            align: 'center',
            width: 120,
        },
        {
            title: '订单折扣',
            dataIndex: 'discountRate',
            ellipsis: true,
            align: 'center',
            width: 80,
        },
        {
            title: '订单日期',
            dataIndex: 'createTime',
            ellipsis: true,
            align: 'center',
            width: 150,
        },
    ];
};

const QueryTable = (props) => {
    const navigate = useNavigate();
    const toMember = (id) => {
        navigate(urlKey + 'member/detail', { state: { id } });
    };
    const toBook = (id) => {
        navigate(urlKey + 'book/detail', { state: { id } });
    };

    return (
        <div className='pageTables'>
            <Table
                className='pageTables'
                // eslint-disable-next-line react/prop-types
                columns={columns(props?.dict, toBook, toMember)}
                pagination={false}
                bordered
                scrollTo={{ x: 1500 }}
                size='small'
                rowKey='id'
                // eslint-disable-next-line react/prop-types
                dataSource={props?.data}
            />
        </div>
    );
};
export default QueryTable;
