/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Col, Drawer, Form, Input, Row, Select, Space, Popconfirm, message, InputNumber } from 'antd';
import { useEffect } from 'react';
import { getOptions } from '../../../../utils/dict';
import { queryMember, topUpRecord, queryMemberById } from '../../../../api/member';

const OptionModel = (props) => {
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState(null);
    const [type, setType] = useState(null);
    const [form] = Form.useForm();
    useEffect(() => {
        let { record, open, type } = props;
        if (record) {
            if (record?.memberId) {
                getMemberById(record?.memberId);
            }
            setValues(record);
            setType(type);
            setOpen(open);
        }
    }, [props]);

    const getMemberById = async (e) => {
        let resp = await queryMemberById({ memberId: e });
        console.log(resp);
        if (resp?.code == '200') {
            let data = resp?.data;
            if (data == null) {
                message.warning('会员不存在');
            } else {
                form.setFieldValue('memberNumber', data.number);
                form.setFieldValue('memberName', data.nick);
                form.setFieldValue('accountPoints', data.accountPoints);
            }
        } else {
            message.warning(resp.message);
        }
    };

    const getMember = async (e) => {
        let resp = await queryMember({ number: e.target.value });
        console.log(resp);
        if (resp?.code == '200') {
            let data = resp?.data;
            if (data == null) {
                message.warning('会员不存在');
            } else {
                form.setFieldValue('memberName', data.nick);
                form.setFieldValue('accountPoints', data.accountPoints);
            }
        } else {
            message.warning(resp.message);
        }
    };

    const onClose = () => {
        form.resetFields();
        setValues(null);
        setOpen(false);
        props?.setOpen(false);
    };

    const saveOption = async (val) => {
        let resp = await topUpRecord(val);
        if (resp?.code == '200') {
            setOpen(false);
            message.success(resp?.message);
            setValues({});
            props?.setOption();
            props?.setOpen(false);
        } else {
            setOpen(true);
            message.warning(resp?.message);
        }
    };

    const onFinsh = () => {
        form.validateFields()
            .then((val) => {
                console.log(val);
                saveOption({ ...values, ...val });
            })
            .catch((err) => {
                // 验证不通过时进入
                console.log(err);
            });
    };

    return (
        <>
            <Drawer
                title='积分充值'
                width={680}
                onClose={onClose}
                open={open}
                destroyOnClose={true}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>取消</Button>
                        <Popconfirm
                            title='确定要保存吗？'
                            placement='topRight'
                            okText='确定'
                            cancelText='取消'
                            onConfirm={onFinsh}>
                            <Button htmlType='submit' type='primary'>
                                保存
                            </Button>
                        </Popconfirm>
                    </Space>
                }>
                <Form initialValues={values} form={form} preserve={false} layout='vertical'>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item maxLength={20} name='memberNumber' label='会员'>
                                <Input
                                    onBlur={(val) => {
                                        getMember(val);
                                    }}
                                    style={{ width: '220px' }}
                                    disabled={type == 2}
                                    placeholder='请输入会员编号或者手机号'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='memberName' label='会员名称'>
                                <Input
                                    maxLength={30}
                                    disabled
                                    style={{ width: '220px' }}
                                    placeholder='请输入会员名称'
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name='accountPoints' label='账户积分'>
                                <InputNumber max={999999999.99} disabled style={{ width: 220 }} min={0} step='1' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name='pointType'
                                label='积分类型'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择积分类型',
                                    },
                                ]}>
                                <Select
                                    style={{ width: '220px' }}
                                    placeholder='积分类型'
                                    allowClear={true}
                                    options={getOptions(props?.dict?.POINT_TYPE || [])}></Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='pointCount'
                                label='积分'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入积分',
                                    },
                                ]}>
                                <InputNumber style={{ width: 220 }} min={0} step='1' />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );
};
export default OptionModel;
