import './App.css';
import { ConfigProvider } from 'antd';
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import routes from './routes/index';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { loginFailure } from './store/actions/loginFailure';
import { getUrlList, getRouteList, getRoute } from './utils/route';
import dayjs from 'dayjs';
import zh_CN from 'antd/es/locale/zh_CN';
import Layout from './layout';
import 'dayjs/locale/zh-cn';
import { urlKey } from './setting';

//导入工具函数 渲染路由
dayjs.locale('zh-cn');
import { noFilter } from './setting';
import { removeCache, userKey, removeToken } from './utils/auth';
import { menuList } from './routes/menu';

function App() {
    const [paddingLeftSize, setPaddingLeftSize] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            navigate(urlKey + 'login');
            removeCache(userKey);
            removeToken();
            dispatch(loginFailure);
        }
    }, [user]);

    const location = useLocation();
    useEffect(() => {
        let path = location.pathname;
        console.log(path);
        if (path === '/ebook-space-web/login') {
            setIsLogin(true); // 当前是登录页，不渲染菜单
        } else {
            setIsLogin(false);
        }
        const routesList = getRouteList(routes);
        if (routesList && !routesList.includes(path)) {
            navigate(urlKey + '404');
        }
        const pathList = getUrlList(menuList);
        if (!noFilter.includes(path) && !pathList.includes(path)) {
            navigate(urlKey + '403');
        }
    }, [location]);

    const setPaddingWidth = (val) => {
        setPaddingLeftSize(val);
    };

    return (
        <>
            <div className='App'>
                <ConfigProvider locale={zh_CN}>
                    {!isLogin ? (
                        <>
                            <div className='layoutMain'>
                                <Layout
                                    setWidht={(val) => {
                                        setPaddingWidth(val);
                                    }}
                                />
                            </div>

                            <div
                                className='appMain'
                                style={{
                                    marginLeft: paddingLeftSize ? '90px' : '218px',
                                    minHeight: window.innerHeight - 60 + 'px',
                                }}>
                                <Routes>
                                    <Route component={Layout}>{getRoute(routes)}</Route>
                                </Routes>
                            </div>
                        </>
                    ) : (
                        <Routes>
                            <Route component={Layout}>{getRoute(routes)}</Route>
                        </Routes>
                    )}
                </ConfigProvider>
            </div>
        </>
    );
}

export default App;
