import React from 'react';
import { Row, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import useDict from '../../../hooks/Dict';
import { useLocation } from 'react-router-dom';
import useDocumentTitle from '../../../hooks/DocumentTitle';
import BreadcrumbTitle from '../../../components/BreadcrumbTitle';

import BookDesc from './component/BookDesc';
import BookMember from './component/BookMember';

const items = (id, dict) => {
    return [
        {
            key: '1',
            label: '订阅记录',
            children: <BookMember id={id} dict={dict} />,
        },
    ];
};

const BookDetail = () => {
    const [id, setId] = useState(null);
    const location = useLocation();

    const dict = useDict(
        'STATE,BOOK_STATE,YSEORNO,BOOK_INTEREST,RESTICTION_TYPE,ORDER_TYPE,BOOK_FILE_TYPE,MEMBER_BOOK_STATE,BOOK_LANGUAGE',
    );
    useDocumentTitle('书籍详情');

    useEffect(() => {
        if (location.state?.id) {
            setId(location.state.id);
        }
    }, []);

    return (
        <div className='appPage'>
            <div className='pageContent'>
                <BreadcrumbTitle list={['书籍管理', '书籍列表', '书籍详情']} />
                {id && <BookDesc dict={dict} id={id} />}
                <Row>
                    <Tabs style={{ width: '100%' }} defaultActiveKey='1' items={items(id, dict)}></Tabs>
                </Row>
            </div>
        </div>
    );
};

export default BookDetail;
