import { useState, useEffect } from 'react';

const useTimeout = (callback, delay, startFlag) => {
    const [timeoutId, setTimeoutId] = useState(null);

    useEffect(() => {
        if (startFlag && delay !== null && delay > 0) {
            const id = setTimeout(() => {
                callback();
            }, delay);

            setTimeoutId(id);
            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [startFlag]);

    return () => {
        clearTimeout(timeoutId);
    };
};

export default useTimeout;
