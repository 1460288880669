/* eslint-disable no-undef */
import React from 'react';
import { Table, Tag, Button } from 'antd';
import { getLable } from '../../../../utils/dict';
import { useNavigate } from 'react-router-dom';
import { urlKey } from '../../../../setting';

const columns = (dict, toMember) => {
    return [
        {
            title: '编号',
            dataIndex: 'number',
            ellipsis: true,
            align: 'center',
            width: 150,
        },
        {
            title: '会员编号',
            dataIndex: 'memberNumber',
            ellipsis: true,
            align: 'center',
            width: 120,
        },
        {
            title: '会员手机号',
            dataIndex: 'memberMobile',
            align: 'center',
            ellipsis: true,
            width: 120,
        },
        {
            title: '会员名称',
            dataIndex: 'memberName',
            ellipsis: true,
            width: 120,
            render: (memberName, record) => (
                <span>
                    <Button type='link' size={'small'} onClick={() => toMember(record.memberId)}>
                        {memberName}
                    </Button>
                </span>
            ),
        },
        {
            title: '积分',
            dataIndex: 'pointCount',
            align: 'center',
            ellipsis: true,
            width: 120,
        },
        {
            title: '类型',
            dataIndex: 'pointType',
            align: 'center',
            width: 110,
            render: (pointType) => (
                <span>
                    <Tag color='processing' key={pointType}>
                        {getLable(pointType, dict.POINT_TYPE)}
                    </Tag>
                </span>
            ),
        },
        {
            title: '折扣',
            dataIndex: 'discountRate',
            ellipsis: true,
            align: 'center',
            width: 80,
        },
        {
            title: '记录日期',
            dataIndex: 'createTime',
            ellipsis: true,
            align: 'center',
            width: 150,
        },
    ];
};

const QueryTable = (props) => {
    const navigate = useNavigate();
    const toMember = (id) => {
        navigate(urlKey + 'member/detail', { state: { id } });
    };

    return (
        <div className='pageTables'>
            <Table
                className='pageTables'
                // eslint-disable-next-line react/prop-types
                columns={columns(props?.dict, toMember)}
                pagination={false}
                bordered
                scrollTo={{ x: 1500 }}
                size='small'
                rowKey='id'
                // eslint-disable-next-line react/prop-types
                dataSource={props?.data}
            />
        </div>
    );
};
export default QueryTable;
