/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Col, Drawer, Form, Input, Row, Space, Popconfirm, message, InputNumber, Select } from 'antd';
import { useEffect } from 'react';
import { topUpByMember, getCardByNum } from '../../../../api/voucher';
import { getOptions } from '../../../../utils/dict';
import { isBlank } from '../../../../utils/string';

const OptionModelTopUp = (props) => {
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState(null);
    const [form] = Form.useForm();
    useEffect(() => {
        let { open } = props;
        setOpen(open);
    }, [props]);

    const onClose = () => {
        form.resetFields();
        setValues(null);
        setOpen(false);
        props?.setOpen(false);
    };

    const getVoucher = async () => {
        let number = form.getFieldValue('voucherNum');
        console.log(number);
        if (isBlank(number)) {
            form.setFieldValue('voucherAmount', null);
            form.setFieldValue('voucherUnit', null);
            return;
        }
        let resp = await getCardByNum({ number });
        if (resp?.code == '200') {
            form.setFieldValue('voucherAmount', resp.data.voucherAmount);
            form.setFieldValue('voucherUnit', resp.data.voucherUnit);
        } else {
            message.warning(resp?.message);
        }
    };

    const saveOption = async (val) => {
        if (isBlank(val.memberPhone) && isBlank(val.memberNumber)) {
            message.warning('会员手机号和会员编号至少输入一项');
            return;
        }
        let resp = await topUpByMember(val);
        if (resp?.code == '200') {
            setOpen(false);
            message.success(resp?.message);
            setValues({});
            props?.setOption();
            props?.setOpen(false);
        } else {
            setOpen(true);
            message.warning(resp?.message);
        }
    };

    const onFinsh = () => {
        form.validateFields()
            .then((val) => {
                saveOption({ ...val });
            })
            .catch((err) => {
                // 验证不通过时进入
                console.log(err);
            });
    };

    return (
        <>
            <Drawer
                title='会员充值'
                width={520}
                onClose={onClose}
                open={open}
                destroyOnClose={true}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>取消</Button>
                        <Popconfirm
                            title='确定要充值吗？'
                            placement='topRight'
                            okText='确定'
                            cancelText='取消'
                            onConfirm={onFinsh}>
                            <Button htmlType='submit' type='primary'>
                                充值
                            </Button>
                        </Popconfirm>
                    </Space>
                }>
                <Form initialValues={values} form={form} preserve={false} layout='vertical'>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name='voucherNum'
                                label='充值卡编号'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入充值卡编号',
                                    },
                                ]}>
                                <Input
                                    onChange={getVoucher}
                                    style={{ width: '220px' }}
                                    placeholder='请输入充值卡编号'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='cvvCode'
                                label='CVV校验码'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入CVV校验码',
                                    },
                                ]}>
                                <Input style={{ width: '220px' }} placeholder='请输入CVV校验码' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='memberNumber' label='会员编号'>
                                <Input style={{ width: '220px' }} placeholder='请输入会员编号' />
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='memberPhone' label='会员手机号'>
                                <Input style={{ width: '220px' }} placeholder='请输入会员手机号' />
                            </Form.Item>
                        </Col>
                    </Row> */}
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name='voucherAmount'
                                label='充值卡面额'
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入充值卡面额',
                                    },
                                ]}>
                                <InputNumber disabled style={{ width: 220 }} min={0} step='1' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='voucherUnit'
                                label='面额单位'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择面额单位',
                                    },
                                ]}>
                                <Select
                                    disabled
                                    style={{ width: '220px' }}
                                    placeholder='请选择面额单位'
                                    allowClear={true}
                                    options={getOptions(props?.dict?.VOUCHER_UNIT || [])}></Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );
};
export default OptionModelTopUp;
