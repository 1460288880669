/* eslint-disable no-undef */
import React from 'react';
import { Table, Tag, Button } from 'antd';
import { getLable } from '../../../../utils/dict';

const columns = ({ remove, edit, dict }) => {
    return [
        {
            title: '卡编号',
            dataIndex: 'voucherNum',
            ellipsis: true,
            align: 'center',
            width: 150,
        },
        {
            title: '卡名称',
            dataIndex: 'voucherName',
            ellipsis: true,
            width: 120,
        },
        {
            title: 'CVV校验码',
            dataIndex: 'cvvCode',
            ellipsis: true,
            align: 'center',
            width: 100,
        },
        {
            title: '类型',
            dataIndex: 'voucherType',
            align: 'center',
            width: 110,
            render: (voucherType) => (
                <span>
                    <Tag color='processing' key={voucherType}>
                        {getLable(voucherType, dict.VOUCHER_TYPE)}
                    </Tag>
                </span>
            ),
        },
        {
            title: '面额',
            dataIndex: 'voucherAmount',
            ellipsis: true,
            align: 'center',
            width: 110,
        },
        {
            title: '单位',
            dataIndex: 'voucherUnit',
            align: 'center',
            width: 80,
            render: (voucherUnit) => (
                <span>
                    <Tag key={voucherUnit}>{getLable(voucherUnit, dict.VOUCHER_UNIT)}</Tag>
                </span>
            ),
        },
        {
            title: '状态',
            dataIndex: 'state',
            align: 'center',
            width: 80,
            render: (state) => (
                <span>
                    <Tag color={state == '1' ? 'success' : state == '-1' ? 'error' : 'processing'} key={state}>
                        {getLable(state, dict.VOUCHER_CARD_STATE)}
                    </Tag>
                </span>
            ),
        },

        {
            title: '发行日期',
            dataIndex: 'voucherDate',
            ellipsis: true,
            align: 'center',
            width: 120,
        },
        {
            title: '有效期',
            dataIndex: 'voucherExpire',
            ellipsis: true,
            align: 'center',
            width: 120,
        },

        {
            title: '使用日期',
            dataIndex: 'useTime',
            ellipsis: true,
            align: 'center',
            width: 150,
        },
        {
            title: '创建日期',
            dataIndex: 'createTime',
            align: 'center',
            ellipsis: true,
            width: 150,
        },
        {
            title: '操作',
            width: 150,
            align: 'center',
            render: (_, record) => (
                <div>
                    <Button type='link' size={'small'} onClick={() => edit(record)}>
                        编辑
                    </Button>
                    {record.state == '1' && (
                        <Button type='link' size={'small'} onClick={() => remove(record, '-1')}>
                            作废
                        </Button>
                    )}
                    {record.state == '-1' && (
                        <Button type='link' size={'small'} onClick={() => remove(record, '1')}>
                            启用
                        </Button>
                    )}
                </div>
            ),
        },
    ];
};

const QueryTable = (props) => {
    return (
        <div className='pageTables'>
            <Table
                className='pageTables'
                columns={columns(props)}
                pagination={false}
                bordered
                scrollTo={{ x: 1500 }}
                size='small'
                rowKey='id'
                // eslint-disable-next-line react/prop-types
                dataSource={props?.data}
            />
        </div>
    );
};
export default QueryTable;
