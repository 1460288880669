/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { getOptions } from '../../../../utils/dict';

import dayjs from 'dayjs';
dayjs.locale('zh-cn');

const QueryForm = (props) => {
    const [form] = Form.useForm();
    const [dict, setDict] = useState([]);

    const onFinish = (values) => {
        // eslint-disable-next-line react/prop-types
        props?.setForm(values);
    };

    useEffect(() => {
        if (props?.dict) {
            setDict(props?.dict);
        }
    }, [props?.dict]);

    return (
        <div style={{ marginBottom: '10px' }}>
            <Form form={form} onFinish={onFinish} layout='inline'>
                <Form.Item name='number' label='ISBN编号'>
                    <Input style={{ width: '200px' }} allowClear placeholder='请输入ISBN编号' />
                </Form.Item>
                <Form.Item name='bookName' label='书籍名称'>
                    <Input style={{ width: '200px' }} allowClear placeholder='请输入书籍名称' />
                </Form.Item>
                <Form.Item name='state' label='状态'>
                    <Select
                        style={{ width: '200px' }}
                        placeholder='请选择状态'
                        allowClear={true}
                        options={getOptions(dict?.BOOK_STATE || [])}></Select>
                </Form.Item>
                <Form.Item>
                    <Button type='primary' htmlType='submit'>
                        查询
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button type='primary' onClick={props?.restForm}>
                        重置
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default QueryForm;
