import { getCache, userKey } from '../../utils/auth';

const initialState = getCache(userKey) || null;

function userReducer(state = initialState, action) {
    switch (action.type) {
        // 处理登录action
        case 'LOGIN_SUCCESS':
            return action.payload;
        case 'LOGIN_FAILURE':
            return null;
        // 其他case...
        default:
            return state;
    }
}
export default userReducer;
