/* eslint-disable no-undef */
import React from 'react';
import { Table, Tag, Popconfirm, Button } from 'antd';
import { getLable, getLevelLable } from '../../../../utils/dict';

const columns = ({ detail, edit, remove, dict, levelList }) => {
    return [
        {
            title: '会员编号',
            dataIndex: 'number',
            width: 100,
            align: 'center',
            ellipsis: true,
            // render: (text) => <a>{text}</a>,
        },
        {
            title: '会员名称',
            dataIndex: 'nick',
            ellipsis: true,
            width: 120,
        },
        {
            title: '会员手机号',
            dataIndex: 'mobile',
            align: 'center',
            ellipsis: true,
            width: 100,
        },
        {
            title: '称呼',
            dataIndex: 'title',
            align: 'center',
            width: 80,
            render: (title) => (
                <span>
                    <Tag color={'success'} key={title}>
                        {getLable(title, dict?.SEX_INFO)}
                    </Tag>
                </span>
            ),
        },

        {
            title: '状态',
            dataIndex: 'status',
            align: 'center',
            width: 80,
            render: (status) => (
                <span>
                    <Tag color={status == '1' ? 'success' : status == '-1' ? 'error' : 'warning'} key={status}>
                        {getLable(status, dict?.STATE)}
                    </Tag>
                </span>
            ),
        },

        {
            title: '会员等级',
            dataIndex: 'memberLevel',
            align: 'center',
            ellipsis: true,
            width: 80,
            render: (memberLevel) => (
                <span>
                    <Tag color={'processing'} key={memberLevel}>
                        {getLevelLable(memberLevel, levelList)}
                    </Tag>
                </span>
            ),
        },
        {
            title: '账户余额',
            dataIndex: 'accountBalance',
            ellipsis: true,
            width: 80,
        },
        {
            title: '会员积分',
            dataIndex: 'accountPoints',
            ellipsis: true,
            width: 80,
        },

        {
            title: '更新时间',
            dataIndex: 'updateTime',
            ellipsis: true,
            align: 'center',
            width: 150,
        },
        {
            title: '操作',
            align: 'center',
            width: 150,
            render: (_, record) => (
                <div>
                    <Button name='详情' type='link' size={'small'} onClick={() => detail(record)}>
                        详情
                    </Button>
                    {/* <Button name='下线会员' type='link' size={'small'} onClick={() => detail(record)}>
                        下线会员
                    </Button> */}
                    <Button type='link' size={'small'} onClick={() => edit(record)}>
                        编辑
                    </Button>
                    <Popconfirm
                        title='确定要删除吗？'
                        placement='topRight'
                        okText='确定'
                        cancelText='取消'
                        onConfirm={() => remove(record.id, record.userNumber)}>
                        <Button danger type='link' size={'small'}>
                            删除
                        </Button>
                    </Popconfirm>
                </div>
            ),
        },
    ];
};

// const data = [
//     {
//         id: '1',
//         userNumber: '123445',
//         userName: 'John Brown',
//         userPhone: '12435677857567',
//         userEmail: '1212345@1245.com',
//         userState: '1',
//         updateDate: 'New York No. 1 Lake Park',
//     },
//     {
//         id: '2',
//         userNumber: '123445',
//         userName: 'John Brown',
//         userPhone: '12435677857567',
//         userEmail: '1212345@1245.com',
//         userState: '2',
//         updateDate: 'London No. 1 Lake Park',
//     },
//     {
//         id: '3',
//         userNumber: '12212345',
//         userName: 'John Brown',
//         userPhone: '12435677857567',
//         userEmail: '1212345@1245.com',
//         userState: '0',
//         updateDate: 'Sydney No. 1 Lake Park',
//     },
// ];
const QueryTable = (props) => {
    return (
        <div className='pageTables'>
            <Table
                className='pageTables'
                columns={columns(props)}
                pagination={false}
                bordered
                rowKey='id'
                size='small'
                // eslint-disable-next-line react/prop-types
                dataSource={props?.data}
            />
        </div>
    );
};
export default QueryTable;
